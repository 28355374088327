/* import __COLOCATED_TEMPLATE__ from './fin-guidance-applied.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type FinGuidanceApplied from 'embercom/objects/inbox/renderable/fin-guidance-applied';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { htmlToTextContent } from 'embercom/lib/html-unescape';

interface FinGuidanceAppliedPart extends RenderablePart {
  renderableData: FinGuidanceApplied;
}

interface Args {
  part: FinGuidanceAppliedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsFinGuidanceAppliedComponent extends Component<Signature> {
  @service declare session: Session;

  get renderableData() {
    return this.args.part.renderableData;
  }

  get guidelines() {
    return this.renderableData.guidelines;
  }

  textToContent(text: string) {
    return htmlToTextContent(text);
  }

  get truncatedGuidelines() {
    return this.guidelines.map((guideline) => {
      let maxTextLength = 100;
      let maxTitleLength = 50;
      let text = htmlToTextContent(guideline.text);
      let title = guideline.title || htmlToTextContent(guideline.text);
      let isTruncatedText = text.length > maxTextLength;
      let isTruncatedTitle = title ? title.length > maxTitleLength : text.length > maxTitleLength;

      return {
        ...guideline,
        text: isTruncatedText ? `${text.slice(0, maxTextLength)}...` : text,
        title: isTruncatedTitle ? `${title.slice(0, maxTitleLength)}...` : title,
      };
    });
  }

  get guidanceRoute() {
    if (this.session.workspace.isStandaloneApp) {
      return 'apps.app.standalone.guidance';
    } else {
      return 'apps.app.automation.fin-ai-agent.guidance';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::FinGuidanceApplied': typeof Inbox2ConversationStreamEventPartsFinGuidanceAppliedComponent;
    'inbox2/conversation-stream/event-parts/fin-guidance-applied': typeof Inbox2ConversationStreamEventPartsFinGuidanceAppliedComponent;
  }
}
