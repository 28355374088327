/* import __COLOCATED_TEMPLATE__ from './merged-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Signature {
  Args: {
    conversation: Conversation;
  };
}
const MergedPanel = templateOnlyComponent<Signature>();
export default MergedPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::MergedPanel': typeof MergedPanel;
  }
}
