/* import __COLOCATED_TEMPLATE__ from './vertical-collection-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLElement;
  Args: {
    resetOnChange: string | number;
  };
  Blocks: {
    default: [];
  };
}

export default class VerticalCollectionWrapper extends Component<Signature> {
  @tracked destroyVerticalCollectionToggle = true;
  currentResetOnChange = this.args.resetOnChange;

  @action toggleDestroyVerticalCollection() {
    // Skip toggling if we're in the process of destroying the component
    if (this.isDestroying) {
      return;
    }

    if (this.args.resetOnChange !== this.currentResetOnChange) {
      this.currentResetOnChange = this.args.resetOnChange;
      this.destroyVerticalCollectionToggle = !this.destroyVerticalCollectionToggle;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::VerticalCollectionWrapper': typeof VerticalCollectionWrapper;
  }
}
