/* import __COLOCATED_TEMPLATE__ from './ticket-details.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { type ConversationResource } from '../conversation-resource';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  conversationResource: ConversationResource;
  isReadOnly?: boolean;
  onSelectBrand?: ((brandId: number) => void) | null | undefined;
}

interface Signature {
  Args: Args;
}

export default class TicketDetailsComponent extends Component<Signature> {
  @trackedReset('args.conversationResource') currentlyVisible = this.conversation.isReplyable;
  @service declare notificationsService: any;
  @service declare session: Session;

  get showTicketAttributesPanel() {
    return !this.currentlyVisible;
  }

  get isDescriptionEmpty() {
    return this.blocksEmpty(this.descriptionAttribute?.value);
  }

  get conversation(): Conversation {
    return this.args.conversationResource.conversation;
  }

  get descriptionDescriptor() {
    return this.conversation.ticketType?.descriptors?.find(
      (descriptor) => descriptor.name === '_default_description_',
    );
  }

  get descriptionAttribute() {
    if (!this.descriptionDescriptor) {
      return;
    }

    return (
      this.conversationAttributesById[this.descriptionDescriptor.id] ??
      new TicketAttributeSummary('', this.descriptionDescriptor)
    );
  }

  get conversationAttributesById() {
    return this.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get ticketDescriptionHighlights() {
    return this.highlights?.ticketDescription;
  }

  get highlights() {
    return this.args.conversationResource.highlights;
  }

  blocksEmpty(value: any) {
    try {
      let blocks = JSON.parse(value);
      return (
        blocks
          .map((block: any) => {
            if (block.type === 'paragraph') {
              return block.text;
            }
            if (['unorderedList', 'orderedList'].includes(block.type)) {
              return block.items.join('');
            }
            return '';
          })
          .join('').length === 0
      );
    } catch (e) {
      return value?.length === 0;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketDetails': typeof TicketDetailsComponent;
    'inbox2/conversation-page/ticket-details': typeof TicketDetailsComponent;
  }
}
