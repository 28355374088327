/* import __COLOCATED_TEMPLATE__ from './install-notification.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import type Service from '@ember/service';
import templateOnly from '@ember/component/template-only';

interface Args {
  dismissalType?: string;
  dismissalKey?: string;
  dismissalService?: Service;
  title: string;
  description: string;
  buttonLabel: string;
  url: string;
  id: string;
  trackObject: string;
}

interface Signature {
  Args: Args;
}

const InstallNotification = templateOnly<Signature>();
export default InstallNotification;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Channel::InstallNotification': typeof InstallNotification;
    'inbox2/channel/install-notification': typeof InstallNotification;
  }
}
