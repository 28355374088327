/* import __COLOCATED_TEMPLATE__ from './nested-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type PopoverAPI, type PopoverArgs } from '@intercom/pulse/template-registry';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { EMBERCOM_PROSEMIRROR_COMPOSER_ALLOW_MOUSEDOWN_CLASS } from '@intercom/embercom-prosemirror-composer/components/editor-api/constants';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { useResource } from 'ember-resources';

export type SimpleOption = {
  name: string;
  onClick: (...args: any[]) => any;
  beta?: boolean;
  paywalled?: boolean;
  paywallFeatureKey?: string;
};

export type NestedOption = {
  name: string;
  nestedOptions: SimpleOption[];
  beta?: boolean;
  paywalled?: boolean;
  paywallFeatureKey?: string;
};

export type NestedDropdownOption = SimpleOption | NestedOption;

interface Signature {
  Element: HTMLElement;
  Args: PopoverArgs & {
    options: Readonly<NestedDropdownOption[]>;
    isSearchable?: boolean;
    // This can be extended to accept other components if we intend to accept
    // more than just the AI assist toolbar option in the future.
    optionComponentName: 'inbox2/composer/toolbar-ai-assist-option';
    keyboardPriority?: number;
    nestedPlacement?: PopoverArgs['placement'];
    nestedShowOn?: PopoverArgs['showOn'];
  };
  Blocks: {
    opener: [PopoverAPI];
  };
}

export default class NestedDropdown extends Component<Signature> {
  @tracked query = '';

  readonly allowMousedownClassNames = EMBERCOM_PROSEMIRROR_COMPOSER_ALLOW_MOUSEDOWN_CLASS;

  readonly selection = useResource(this, NavigableSelection, () => ({
    items: this.options,
    equalityComparatorFn: (a: NestedDropdownOption, b: NestedDropdownOption) => {
      return a.name === b.name;
    },
  }));

  get options() {
    return this.args.options.filter((option) => {
      return option.name.toLowerCase().includes(this.query.toLowerCase());
    });
  }

  get keyboardPriority() {
    return this.args.keyboardPriority ?? 0;
  }

  isNestedOption(option: SimpleOption | NestedOption): option is NestedOption {
    return 'nestedOptions' in option;
  }

  @action updateQuery(event: InputEvent & { target: HTMLInputElement }) {
    this.query = event.target?.value;
  }

  @action onClick(option: NestedDropdownOption) {
    if ('onClick' in option) {
      option.onClick();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::NestedDropdown': typeof NestedDropdown;
    'inbox2/common/nested-dropdown': typeof NestedDropdown;
  }
}
