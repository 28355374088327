/* import __COLOCATED_TEMPLATE__ from './contact.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  conversationEntry: ConversationTableEntry;
  isSelected: boolean;
  toggleSelection: (event: Event) => unknown;
}

interface Signature {
  Args: Args;
}

export default class Contact extends Component<Signature> {
  @service declare session: Session;

  @tracked isHovering = false;

  @action toggleSelection(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.args.toggleSelection(event);
  }

  get isRestricted(): boolean {
    return this.args.conversationEntry.redacted;
  }

  get isNotRestricted(): boolean {
    return !this.isRestricted;
  }

  get currentUser() {
    let { conversationEntry } = this.args;
    if (conversationEntry.firstParticipant) {
      return conversationEntry.firstParticipant?.displayAs;
    }
    return conversationEntry.user.displayAs;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Contact': typeof Contact;
    'inbox2/conversations-table/table-cells/contact': typeof Contact;
  }
}
