/* import __COLOCATED_TEMPLATE__ from './channel-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { Channel } from 'embercom/models/data/inbox/channels';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type InboundEmail } from 'embercom/objects/inbox/renderable/user-email-comment';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

interface Args {
  channel: Channel;
  ua?: string;
  url?: string;
  email?: InboundEmail;
  conversation: Conversation;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

interface iTooltipData {
  url?: string;
  ua?: string;
  id?: number;
  label?: string;
}

type TooltipData = iTooltipData | undefined;

export default class UnnamedComponent extends Component<Signature> {
  @service declare session: Session;
  allChannels = Channel;

  get tooltipData(): TooltipData {
    switch (this.args.channel) {
      case Channel.Desktop:
        return this.desktopData;
      case Channel.Email:
        return this.emailData;
      case Channel.Twitter:
        return this.twitterData;
      case Channel.Facebook:
        return this.facebookData;
    }
    return undefined;
  }

  get desktopData(): TooltipData {
    if (this.args.url === undefined && this.args.ua === undefined) {
      return undefined;
    }
    return { url: this.args.url, ua: this.args.ua };
  }

  get emailData(): TooltipData {
    if (this.args.email?.id === undefined) {
      return undefined;
    }
    return { id: this.args.email.id };
  }

  get twitterData(): TooltipData {
    if (this.args.url === undefined) {
      return undefined;
    }

    let username = extractTwitterUsername(this.args.url);
    if (username === undefined) {
      return undefined;
    }
    return {
      label: sanitizeHtml(
        `<a data-test-channel-tooltip-link
        target="_blank" class="text-link  no-underline" href=${this.args.url}>${username}</a>`,
      ),
    };
  }

  get facebookData(): TooltipData {
    if (this.args.url === undefined) {
      return undefined;
    }
    return { url: this.args.url };
  }
}

function extractTwitterUsername(url: string): string | undefined {
  let matches = url.match(/^https?:\/\/twitter.com\/(.+)/i);
  if (!matches || matches.length < 2 || !matches[1]) {
    return undefined;
  }

  return matches[1];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::ChannelTooltip': typeof UnnamedComponent;
    'inbox2/common/channel-tooltip': typeof UnnamedComponent;
  }
}
