/* import __COLOCATED_TEMPLATE__ from './merge-into-confirm.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Snackbar from 'embercom/services/snackbar';
import type CommandKService from 'embercom/services/command-k';
import { tracked } from '@glimmer/tracking';

interface Args {
  primaryConversationSummary: ConversationRecord;
  secondaryConversationSummary: ConversationRecord;
  onClose: () => void;
  isCrossUserMergeEnabled: boolean;
  onMergeConversation: (
    primary: ConversationRecord,
    secondary: ConversationRecord,
  ) => Promise<void>;
}

const VISIBLE_PARTICIPANTS_LIMIT = 5;

export default class MergeIntoConfirm extends Component<Args> {
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;

  @tracked isDisabled = false;

  get shouldShowConfirmationModal(): boolean {
    return this.args.isCrossUserMergeEnabled;
  }

  get uniqueParticipantEmails(): string[] {
    let allParticipants = [
      ...(this.args.primaryConversationSummary.participantSummaries || []),
      ...(this.args.secondaryConversationSummary.participantSummaries || []),
    ];

    // Create a Set of unique emails, filtering out undefined values
    let uniqueEmails = new Set(
      allParticipants
        .map((participant) => participant.email)
        .filter((email): email is string => email !== undefined && email !== null),
    );

    return Array.from(uniqueEmails);
  }

  get visibleParticipants(): string[] {
    return this.uniqueParticipantEmails.slice(0, VISIBLE_PARTICIPANTS_LIMIT);
  }

  get remainingParticipants(): string[] {
    return this.uniqueParticipantEmails.slice(VISIBLE_PARTICIPANTS_LIMIT);
  }

  get hasMoreParticipants(): boolean {
    return this.uniqueParticipantEmails.length > VISIBLE_PARTICIPANTS_LIMIT;
  }

  get remainingParticipantsCount(): number {
    return this.remainingParticipants.length;
  }

  @action async onConfirm() {
    if (!this.shouldShowConfirmationModal) {
      this.args.onClose();
      return;
    }

    this.isDisabled = true;

    try {
      await this.args.onMergeConversation(
        this.args.primaryConversationSummary,
        this.args.secondaryConversationSummary,
      );
    } catch (error) {
      let response = await error.response.json();
      this.snackbar.notifyError(response.errors);
    } finally {
      this.isDisabled = false;
    }

    this.args.onClose();
    this.commandK.hide();
  }

  @action onClose() {
    this.args.onClose();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'exit',
      object: 'confirmation_merge_preview_modal_warning',
      section: 'conversation_merge',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::MergeInto::MergeIntoConfirm': typeof MergeIntoConfirm;
    'inbox2/command-k/merge-into/merge-into-confirm': typeof MergeIntoConfirm;
  }
}
