/* import __COLOCATED_TEMPLATE__ from './on-hotkey.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';

interface Args {
  callback: (e: KeyboardEvent, ekb?: any) => any;
  hotkey: Hotkey;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class OnHotkey extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare inboxHotkeys: any;

  @action
  async callback(e: KeyboardEvent) {
    if (this.inboxHotkeys.hotkeysEnabled) {
      this.args.callback(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::OnHotkey': typeof OnHotkey;
  }
}
