/* import __COLOCATED_TEMPLATE__ from './chat-message.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { type BlockList } from '@intercom/interblocks.ts';
import {
  type FinSource,
  type InboxAssistantConversationPart,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import {
  AnswerStreamingStatus,
  type NormalizedFinSource,
  type CopilotChatMessageAnalyticsData,
  type CopilotInteractionMetadata,
  normalizeFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { action } from '@ember/object';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type InboxState from 'embercom/services/inbox-state';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { timeout } from 'ember-concurrency';
import { type TransitionContext } from 'ember-animated';
import { type AiAssistPromptKey } from 'embercom/resources/inbox2/composer/ai-assist';
import { type PublicAPI } from '../conversation-reply-composer';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type Router from '@ember/routing/router-service';

export interface ChatMessageArgs {
  instrumentRelatedContentPreviewCopy: (message: InboxAssistantConversationPart) => void;
  instrumentRelatedContentClick: (
    source: { entity_id: number; entity_type: EntityType },
    message: InboxAssistantConversationPart,
  ) => void;
  isFirstMessage: boolean;
  isFirstFinMessage: boolean;
  isLastMessage: boolean;
  canAutoScroll: boolean;
  answerStreamingStatus?: AnswerStreamingStatus;
  latestConversationIsLoading: boolean;
  message: InboxAssistantConversationPart;
  inbox: Inbox | { id: string; category: string };
  insertBlocks: (blocks: BlockList, conversationId?: number) => void;
  insertNoteBlocks: (blocks: BlockList) => void;
  instrumentAnswerCopy: (message: InboxAssistantConversationPart) => void;
  instrumentAnswerInsertion: (message: InboxAssistantConversationPart) => void;
  instrumentNoteInsertion: (message: InboxAssistantConversationPart) => void;
  instrumentSourceInsertion: (
    source: NormalizedFinSource,
    message: InboxAssistantConversationPart,
  ) => void;
  analyticsData: CopilotChatMessageAnalyticsData;
  openSourcePreview: (
    content: FinSource,
    analyticsData: CopilotChatMessageAnalyticsData,
    highlightedBlockIndices?: [number, number],
    articleVersionId?: string,
  ) => void;
  composerApi: PublicAPI;
  shouldRenderFirstAnswerTooltip: boolean;
  showAttemptingFallbackMessage?: boolean;
  firstConversationParticipant?: UserSummary;
  isNoAnswerFallback?: boolean;
}

interface Signature {
  Element: Element;
  Args: ChatMessageArgs;
  Blocks: {
    default: [];
  };
}

export default class ChatMessage extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare router: Router;

  @tracked hoveredSourceKey: string | null = null;

  get isStreamingRelatedContent() {
    return this.args.answerStreamingStatus === AnswerStreamingStatus.STREAMING_RELATED_CONTENT;
  }

  get answerIsStreaming() {
    return this.args.answerStreamingStatus === AnswerStreamingStatus.STREAMING_ANSWER;
  }

  get answerIsSending() {
    return this.args.answerStreamingStatus === AnswerStreamingStatus.SENDING;
  }

  get answerIsFinishedStreaming() {
    return this.args.answerStreamingStatus === AnswerStreamingStatus.FINISHED;
  }

  get normalizedRelatedContentData(): NormalizedFinSource[] {
    return this.normalizeData(
      this.args.isNoAnswerFallback
        ? this.args.message.fallbackRelatedContent
        : this.args.message.relatedContent,
    );
  }

  get normalizedSourceData(): NormalizedFinSource[] {
    return this.normalizeData(this.args.message.sources);
  }

  get hoveredSourceUniqueKey(): string | null {
    return this.hoveredSourceKey;
  }

  normalizeData(data?: FinSource[]): NormalizedFinSource[] {
    return (
      data?.flatMap((content) => {
        return {
          ...normalizeFinSource(content, this.session.workspace.id, this.router),
          onClickLink: (opts: CopilotInteractionMetadata, event: PointerEvent) =>
            this.onLinkClick(content, opts, event),
        } as NormalizedFinSource;
      }) || []
    ).map((content) => {
      return {
        ...content,
        instrumentCopy: (opts: CopilotInteractionMetadata) =>
          this.instrumentRelatedContentCopy(content, opts),
        instrumentClick: (opts: CopilotInteractionMetadata) =>
          this.instrumentRelatedContentClick(content, opts),
      };
    });
  }

  delayedEntryTransition = function* ({ insertedSprites, removedSprites }: TransitionContext) {
    for (let sprite of [...removedSprites, ...insertedSprites]) {
      fadeOut(sprite, { duration: 0 });
    }
    yield timeout(500);
    for (let sprite of insertedSprites) {
      yield fadeIn(sprite, { duration: 50 });
    }
  };

  @action setHoveredSourceKey(sourceKey: string | null) {
    this.hoveredSourceKey = sourceKey;
  }

  @action onLinkClick(
    content: FinSource,
    metadata: CopilotInteractionMetadata,
    event: PointerEvent,
  ) {
    if (metadata.openInSidebar) {
      event.preventDefault();

      if (
        content.entity_type === EntityType.SlackThread ||
        content.entity_type === EntityType.ExternalContent
      ) {
        safeWindowOpen(content.data.source_url, '_blank');
      } else {
        this.args.openSourcePreview(
          content,
          this.args.analyticsData,
          metadata.highlightedBlockIndices,
          metadata.articleVersionId,
        );
      }
    }

    this.instrumentRelatedContentClick(content, metadata);
  }

  @action instrumentRelatedContentClick(
    content: { entity_id: number; entity_type: EntityType },
    metadata: CopilotInteractionMetadata,
  ) {
    let object: string;
    let context: string;

    if (metadata.openInSidebar && !metadata.isFromRelatedContentSheet) {
      context = 'related_content';
      object = 'related_content_preview';
    } else if (metadata.isInlineSource) {
      context = 'inline_source';
      object = 'inline_source_preview';
    } else if (metadata.isFromRelatedContentSheet) {
      context = 'related_content';
      object = 'related_content_list_item';
    } else {
      context = 'related_content';
      object = 'related_content_new_window_button';
    }

    this.intercomEventService.trackAnalyticsEvent({
      object,
      action: 'clicked',
      context,
      entity_id: content.entity_id,
      entity_type: content.entity_type,
      inbox_assistant_part_id: this.args.message.persistedId,
      ...this.args.analyticsData,
    });
  }

  @action instrumentRelatedContentCopy(
    content: { entity_id: number; entity_type: EntityType },
    metadata: CopilotInteractionMetadata,
  ) {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: metadata.isFromRelatedContentSheet
          ? 'related_content_list_item'
          : 'related_content_preview',
        action: 'manually_copied',
        context: 'related_content',
        entity_id: content.entity_id,
        entity_type: content.entity_type,
        inbox_assistant_part_id: this.args.message.persistedId,
        text: selection,
        ...this.args.analyticsData,
      });
    }
  }

  @action stripCitationLinksFromText(text: string): string {
    // Regex below is used to identify any link tags with inline-citation class
    let inlineCitationLinkRegex = new RegExp('<a class=\\"inline-citation\\".*?</a>', 'g');
    return text.replaceAll(inlineCitationLinkRegex, '');
  }

  @action stripCitationLinksFromBlocks(blocks: BlockList): BlockList {
    return blocks.map((block) => {
      if (block.type === 'paragraph') {
        let strippedText = this.stripCitationLinksFromText(block.text);
        return { type: 'paragraph', text: strippedText };
      }
      return block;
    });
  }

  @cached
  get strippedMessageBlocks(): BlockList {
    return this.stripCitationLinksFromBlocks(
      this.args.isNoAnswerFallback
        ? this.args.message.fallbackContent ?? []
        : this.args.message.content,
    );
  }

  @action insertSourceBlocksIntoComposer(
    source: NormalizedFinSource,
    message: InboxAssistantConversationPart,
  ) {
    let strippedBlocks = this.stripCitationLinksFromBlocks(source.data.body);
    this.args.insertBlocks(strippedBlocks, message.inboxConversationId);
    this.args.instrumentSourceInsertion(source, message);
  }

  @action async changeToneAndInsertBlocks(promptKey: AiAssistPromptKey) {
    this.args.composerApi.setActiveReplyPane();
    let content = await this.args.composerApi.aiAssistCompletion(
      promptKey,
      this.strippedMessageBlocks,
    );
    this.args.composerApi?.replaceBlocks(content, this.args.message.inboxConversationId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::ChatMessage': typeof ChatMessage;
    'inbox2/copilot/ChatMessage': typeof ChatMessage;
  }
}
