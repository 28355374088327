/* import __COLOCATED_TEMPLATE__ from './name-and-email-pill.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Args {
  model: UserSummary | AdminWithPermissions | SenderEmailAddressSummary;
  onDelete?: boolean;
  mightDelete?: boolean;
  isInvalid?: boolean;
  isNewParticipant?: boolean;
  isRemovedParticipant?: boolean;
  tooltipContent?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class NameAndEmailPillComponent extends Component<Signature> {
  @service declare session: Session;
  get state() {
    if (this.args.isInvalid) {
      return 'invalid';
    }
    if (this.args.isNewParticipant) {
      return 'newParticipant';
    }
    if (this.args.isRemovedParticipant) {
      return 'removedParticipant';
    }
    return 'default';
  }

  get stateClasses() {
    return {
      invalid: this.invalidClasses,
      newParticipant: this.newParticipantClasses,
      removedParticipant: this.removedParticipantClasses,
      default: this.defaultClasses,
    }[this.state];
  }

  get isAliasEnabled() {
    return (
      this.session.workspace.isFeatureEnabled('team-messenger-admin-alias') &&
      !!(this.args.model instanceof AdminWithPermissions && this.args.model.aliasEnabled)
    );
  }

  private get invalidClasses() {
    let deleteStyling = this.args.mightDelete ? ' ring-2 ring-opacity-50 ring-error-border' : '';
    return `bg-error-container text-error ${deleteStyling}`;
  }

  private get newParticipantClasses() {
    return 'bg-notice-container text-default';
  }

  private get removedParticipantClasses() {
    return 'opacity-40 bg-notice-container text-default';
  }

  private get defaultClasses() {
    if (this.args.mightDelete) {
      return 'bg-neutral-container-emphasis text-link ring-2 ring-opacity-50';
    }
    return 'bg-neutral-container text-default';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::NameAndEmailPill': typeof NameAndEmailPillComponent;
    'inbox2/common/name-and-email-pill': typeof NameAndEmailPillComponent;
  }
}
