/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import type DashboardSettingsService from 'embercom/services/dashboard-settings-service';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { action } from '@ember/object';
import type InboxApi from 'embercom/services/inbox-api';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
//@ts-ignore
import Predicate from 'predicates/models/predicate';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type View from 'embercom/objects/inbox/inboxes/view';
import type DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import Component from '@glimmer/component';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type InboxState from 'embercom/services/inbox-state';
import type Snackbar from 'embercom/services/snackbar';

interface Args {
  inboxState: InboxState;
  queryParams: any;
}

interface Signature {
  Args: Args;
}

const DEFAULT_TIMEFRAME = 8;

export enum TableWidth {
  ExtraCompact = 'extra_compact',
  Compact = 'compact',
  Expanded = 'expanded',
}

export default class DashboardComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare dashboardSettingsService: DashboardSettingsService;
  @service declare permissionsService: any;
  @service declare inboxApi: InboxApi;
  @service declare snackbar: Snackbar;

  // general
  @tracked selectedTab = 'inboxes';
  @tracked declare dashboardSettings: DashboardSettings;
  @tracked availableInboxes: Inbox[] = [];
  @tracked tableWidth: TableWidth = TableWidth.Compact;

  // inboxes
  @tracked selectedInboxIds: string[] = [];
  @tracked selectedInboxOverviewColumns: string[] = [];
  @tracked selectedInboxOverviewTimeframe = 0;

  // teammates
  @tracked selectedAssigneeIds: string[] = [];
  @tracked selectedTeammateActivityColumns: string[] = [];
  @tracked selectedTeammateActivityTimeframe = 0;
  @tracked selectedTeammateStatuses: string[] = [];

  // drilldown
  @tracked isDrilldownDrawerOpen = false;
  @tracked tempViewUrl = '';
  @tracked selectedDrilldownCell: any = {};

  // preview-panel
  @tracked isPreviewPanelOpen = false;
  @tracked conversationIdToPreview = -1;

  queryParams: any = this.args.queryParams;
  tempView = false;
  showBanner = false;
  tempSelectedTeamId?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.setup).perform();
    this.setAsTempViewIfNeeded();
  }

  get defaultSelectedInboxIds() {
    return this.topInboxes?.map(({ id }) => id).slice(0, 2) || [];
  }

  get defaultSelectedAssigneeIds() {
    return this.topInboxes?.map(({ id }) => id).slice(0, 1) || [];
  }

  get topInboxes() {
    return this.availableInboxes.filter((i) => i.type === InboxType.Team);
  }

  get temporalViews() {
    return (
      this.availableInboxes.filter((i) => i.category === InboxCategory.View) as View[]
    ).filter((view) => this._isTemporalView(view.viewSummary));
  }

  get isActiveTeammatesMetric() {
    return this.selectedDrilldownCell.metricType === 'active_teammates';
  }

  get tableWidthClass() {
    let panelOpen: boolean = this.isDrilldownDrawerOpen || this.isPreviewPanelOpen;
    let activeTeammatesPanelOpen = false;
    if (this.isActiveTeammatesMetric) {
      activeTeammatesPanelOpen = this.isDrilldownDrawerOpen;
      panelOpen = false;
    }
    if (!this.args.inboxState.isInboxListHidden && panelOpen) {
      return 'inbox2__dashboard__content-width-with-leftnav-and-drawer';
    }
    if (!this.args.inboxState.isInboxListHidden && activeTeammatesPanelOpen) {
      return 'inbox2__dashboard__content-width-with-leftnav-and-large-drawer';
    }
    if (!this.args.inboxState.isInboxListHidden && !panelOpen && !activeTeammatesPanelOpen) {
      return 'inbox2__dashboard__content-width-with-leftnav';
    }
    if (this.args.inboxState.isInboxListHidden && panelOpen) {
      return 'inbox2__dashboard__content-width-with-drawer';
    }
    if (this.args.inboxState.isInboxListHidden && activeTeammatesPanelOpen) {
      return 'inbox2__dashboard__content-width-with-large-drawer';
    }
    return 'inbox2__dashboard__content-width';
  }

  get selectedTeamAndTeammateIds() {
    return this.selectedAssigneeIds.map((id) => +id);
  }

  get selectedTimeframe() {
    if (this.selectedTab === 'inboxes') {
      return this.selectedInboxOverviewTimeframe;
    } else if (this.selectedTab === 'teammates') {
      return this.selectedTeammateActivityTimeframe;
    }
    return DEFAULT_TIMEFRAME;
  }

  setAsTempViewIfNeeded() {
    if (this.queryParams['type'] === 'temp') {
      this.selectedTab = 'teammates';
      this.tempView = true;
      this.showBanner = true;
      this.tempSelectedTeamId = this.queryParams['teamId'];
    } else {
      this.selectedTab = 'inboxes';
      this.tempView = false;
      this.showBanner = false;
      this.tempSelectedTeamId = '';
    }
  }

  // Setup
  @task({ drop: true })
  *loadDashboardSettingsTask() {
    yield this.loadDashboardSettings();
  }

  @task({ drop: true })
  *loadInboxesAndViewsTask() {
    yield this.loadInboxesAndViews();
  }

  @task({ drop: true })
  *setup() {
    yield this.loadDashboardSettings();
    yield this.loadInboxesAndViews();
  }

  @action async loadDashboardSettings() {
    let dashboardSettings = await this.dashboardSettingsService.fetchDashboardSettings();

    if (dashboardSettings !== null) {
      this.dashboardSettings = dashboardSettings;
      this.selectedAssigneeIds = this.dashboardSettings.teamIds;
      this.selectedInboxIds = this.dashboardSettings.inboxIds;
    } else {
      this.selectedInboxIds = this.defaultSelectedInboxIds;
      this.selectedAssigneeIds = this.defaultSelectedAssigneeIds;
      this.dashboardSettings = await this.dashboardSettingsService.createDashboardSettings(
        this.selectedInboxIds,
        this.selectedAssigneeIds,
      );
    }
    this.selectedInboxOverviewColumns = this.dashboardSettings.selectedInboxOverviewColumns;
    this.selectedInboxOverviewTimeframe =
      this.dashboardSettings.selectedInboxOverviewTimeframe || DEFAULT_TIMEFRAME;
    this.selectedTeammateActivityColumns = this.dashboardSettings.selectedTeammateActivityColumns;
    this.selectedTeammateActivityTimeframe =
      this.dashboardSettings.selectedTeammateActivityTimeframe || DEFAULT_TIMEFRAME;
    this.selectedTeammateStatuses = this.dashboardSettings.selectedTeammateStatuses;
    if (this.tempView) {
      this.selectedAssigneeIds = this.tempSelectedTeamId ? [this.tempSelectedTeamId] : [];
      this.selectedTeammateStatuses = [];
    }
    return dashboardSettings;
  }

  @action async loadInboxesAndViews() {
    let { inboxes } = await this.inboxApi.listInboxes({
      types: [InboxType.Team, InboxType.View],
    });
    this.availableInboxes = inboxes.sortBy('name');
    this.updateIfTemporalViewSelected();
  }

  updateIfTemporalViewSelected() {
    let temporalViewIds = this.temporalViews.map((view) => view.id);
    let isTemporalSelected = this.selectedInboxIds.filter((id) =>
      temporalViewIds.includes(id),
    ).length;
    if (isTemporalSelected) {
      this.selectedInboxIds = this.selectedInboxIds.filter((id) => !temporalViewIds.includes(id));
      this.updateDashboardSettings();
    }
  }

  @action updateTableWidth(element: HTMLElement) {
    if (element.clientWidth <= 960) {
      this.tableWidth = TableWidth.ExtraCompact;
    } else if (element.clientWidth >= 961 && element.clientWidth <= 1152) {
      this.tableWidth = TableWidth.Compact;
    } else {
      this.tableWidth = TableWidth.Expanded;
    }
  }
  // Update Inbox settings
  @action
  async updateSelectedInboxIds(newSelectedInboxIds: string[]) {
    let selectedInboxIdsBefore = this.selectedInboxIds;
    let inboxIdsBefore = this.dashboardSettings.inboxIds;

    this.selectedInboxIds = newSelectedInboxIds;
    this.dashboardSettings.inboxIds = this.selectedInboxIds;

    try {
      await this.updateDashboardSettings();
    } catch (e) {
      // Revert change in the UI
      this.selectedInboxIds = selectedInboxIdsBefore;
      this.dashboardSettings.inboxIds = inboxIdsBefore;

      // Show error in the UI
      this.snackbar.notifyError(e.jqXHR.responseJSON.errors[0].message);

      // Throw error again so caller can handle error too
      throw e;
    }
  }

  @action
  updateSelectedInboxOverviewColumns(newColumns: string[]) {
    this.selectedInboxOverviewColumns = newColumns;
    this.dashboardSettings.selectedInboxOverviewColumns = this.selectedInboxOverviewColumns;
    this.updateDashboardSettings();
  }

  @action
  updateSelectedInboxOverviewTimeframe(newTimeframe: number) {
    this.selectedInboxOverviewTimeframe = newTimeframe;
    this.dashboardSettings.selectedInboxOverviewTimeframe = this.selectedInboxOverviewTimeframe;
    this.updateDashboardSettings();
  }

  // Update teammates settings
  @action
  async updateSelectedAssigneeIds(newSelectedAssigneeIds: string[]) {
    let selectedAssigneeIdsBefore = this.selectedAssigneeIds;
    let teamIdsBefore = this.dashboardSettings.teamIds;

    this.selectedAssigneeIds = newSelectedAssigneeIds;
    this.dashboardSettings.teamIds = this.selectedAssigneeIds;

    try {
      await this.updateDashboardSettings();
    } catch (e) {
      // Revert change in the UI
      this.selectedAssigneeIds = selectedAssigneeIdsBefore;
      this.dashboardSettings.teamIds = teamIdsBefore;

      // Show error in the UI
      this.snackbar.notifyError(e.jqXHR.responseJSON.errors[0].message);

      // Throw error again so caller can handle error too
      throw e;
    }
  }

  @action
  updateSelectedTeammateActivityColumns(newColumns: string[]) {
    this.selectedTeammateActivityColumns = newColumns;
    this.dashboardSettings.selectedTeammateActivityColumns = this.selectedTeammateActivityColumns;
    this.updateDashboardSettings();
  }

  @action
  updateSelectedTeammateActivityTimeframe(newTimeFrame: number) {
    this.selectedTeammateActivityTimeframe = newTimeFrame;
    this.dashboardSettings.selectedTeammateActivityTimeframe =
      this.selectedTeammateActivityTimeframe;
    this.updateDashboardSettings();
  }

  @action
  updateSelectedTeammateStatuses(newStatuses: string[]) {
    this.selectedTeammateStatuses = newStatuses;
    this.dashboardSettings.selectedTeammateStatuses = this.selectedTeammateStatuses;
    this.updateDashboardSettings();
  }

  @action
  async updateDashboardSettings() {
    if (this.tempView) {
      return;
    }

    await this.dashboardSettingsService.updateDashboardSettings(this.dashboardSettings);
  }

  @action openDrilldownDrawer(cell: any) {
    this.selectedDrilldownCell = cell;
    this.tempViewUrl = `/a/inbox/${this.session.workspace.id}/dashboard?teamId=${this.selectedDrilldownCell.inboxId}&type=temp`;
    this.isDrilldownDrawerOpen = true;
  }

  @action openPreviewPanel(conversationId: number) {
    this.isDrilldownDrawerOpen = false;
    this.conversationIdToPreview = conversationId;
    this.isPreviewPanelOpen = true;
  }

  @action backToDrilldownDrawer() {
    this.isPreviewPanelOpen = false;
    this.isDrilldownDrawerOpen = true;
  }

  @action closeConversationPreview() {
    this.isPreviewPanelOpen = false;
    this.isDrilldownDrawerOpen = false;
  }

  @action
  _isTemporalView(view: ViewSummary) {
    return [...Predicate.walkPredicates(view.predicates.predicates)].any(
      (p) =>
        p.attribute === 'conversation.waiting_since' ||
        p.attribute === 'conversation.next_breach_time',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Main': typeof DashboardComponent;
    'inbox2/dashboard/main': typeof DashboardComponent;
  }
}
