/* import __COLOCATED_TEMPLATE__ from './away-reasons-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { AWAY_REASONS } from 'embercom/services/admin-away-service';

interface Args {
  header: string;
  reasons: any;
}

interface Signature {
  Args: Args;
}

export default class AwayReasonBreakdown extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare adminAwayService: AdminAwayService;

  get usingCustomReasons() {
    return this.adminAwayService.canEditAwayStatusReasons;
  }

  get reasonsToDisplay() {
    return [
      {
        label: this.intl.t('inbox.user-menu.reasons.default'),
        value: this.args.reasons[''] || 0,
        labelWithoutEmoji: this.intl.t('inbox.user-menu.reasons.default'),
        customReason: null,
      },
      ...(this.adminAwayService.canEditAwayStatusReasons
        ? this.adminAwayService.awayStatusReasons.map((reason) => ({
            label: reason.label,
            labelWithoutEmoji: '',
            customReason: reason,
            value: this.args.reasons[reason.id] || 0,
          }))
        : AWAY_REASONS.map((reason) => ({
            label: this.intl.t(
              `inbox.user-menu.reasons.${this.adminAwayService.getTranslationKeyForAwayReason(reason)}`,
            ),
            labelWithoutEmoji: this.intl.t(
              `inbox.user-menu.reasons.${this.adminAwayService.getTranslationKeyForAwayReason(reason)}`,
            ),
            value: this.args.reasons[reason] || 0,
            customReason: null,
          }))),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::SummaryCard::AwayReasonsBreakdown': typeof AwayReasonBreakdown;
    'inbox2/dashboard/summary-card/away-reasons-breakdown': typeof AwayReasonBreakdown;
  }
}
