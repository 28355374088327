/* import __COLOCATED_TEMPLATE__ from './no-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { type RelatedContentArgs } from '../related-content';
import type RelatedContent from '../related-content';
import { type WithBoundArgs } from '@glint/template';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { isEmpty } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

export interface NoAnswerArgs {
  message: InboxAssistantConversationPart;
  relatedContentComponent: WithBoundArgs<typeof RelatedContent, keyof RelatedContentArgs>;
}

interface Signature {
  Element: Element;
  Args: NoAnswerArgs;
  Blocks: {
    default: [];
  };
}

export default class NoAnswer extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  get knowledgeBaseUrl() {
    return `a/apps/${this.session.workspace.id}/knowledge-hub/all-content`;
  }

  get addContentUrl() {
    return `a/apps/${this.session.workspace.id}/knowledge-hub/overview?tab=ai-copilot`;
  }

  get descriptionTranslations() {
    // We need to hardcode these translations because we're only translating 5 languages
    // in the Inbox, and Copilot multilingual can chat in 47 languages. We don't want to
    // translate too much more, but this is actual copy in an answer.
    /* eslint-disable @intercom/intercom/no-bare-strings */
    return {
      ar: `لم يتمكن Copilot من العثور على إجابة في مركز معرفة فريقك أو تاريخ المحادثة. يرجى إعادة الصياغة أو <a href="${this.addContentUrl}" target="_blank">إضافة محتوى</a> لمساعدة Copilot في الإجابة على المزيد من الأسئلة.`,
      bg: `Copilot не успя да намери отговор в хъба за знания на вашия екип или в историята на разговорите. Моля, преформулирайте или <a href="${this.addContentUrl}" target="_blank">добавете съдържание</a>, за да помогнете на Copilot да отговори на повече въпроси.`,
      bn: `Copilot আপনার দলের জ্ঞানভান্ডার বা কথোপকথনের ইতিহাসে কোনও উত্তর খুঁজে পাইনি। দয়া করে পুনরায় লিখুন বা <a href="${this.addContentUrl}" target="_blank">Copilot উত্তর দিতে সাহায্য করার জন্য বিষয়বস্তু যোগ করুন</a>।`,
      bs: `Copilot nije mogao pronaći odgovor u centru za znanje vašeg tima ili istoriji razgovora. Molimo vas da preformulišete ili <a href="${this.addContentUrl}" target="_blank">dodate sadržaj</a> kako biste pomogli Copilot da odgovori na više pitanja.`,
      ca: `Copilot no ha pogut trobar una resposta al centre de coneixement del teu equip ni a l\`historial de converses. Si us plau, torna a formular-ho o <a href="${this.addContentUrl}" target="_blank">afegeix contingut</a> per ajudar a Copilot a respondre més preguntes.`,
      cs: `Copilot nemohl najít odpověď ve znalostní bázi vašeho týmu nebo v historii konverzace. Prosím, přeformulujte nebo <a href="${this.addContentUrl}" target="_blank">přidejte obsah</a>, abyste pomohli Copilot odpovědět na více otázek.`,
      da: `Copilot kunne ikke finde et svar i dit teams videnshub eller samtalehistorik. Venligst omformuler eller <a href="${this.addContentUrl}" target="_blank">tilføj indhold</a> for at hjælpe Copilot med at besvare flere spørgsmål.`,
      de: `Copilot konnte keine Antwort im Wissenshub Ihres Teams oder in der Gesprächshistorie finden. Bitte formulieren Sie um oder <a href="${this.addContentUrl}" target="_blank">fügen Sie Inhalte hinzu</a>, um Copilot zu helfen, mehr Fragen zu beantworten.`,
      'de-form': `Copilot konnte keine Antwort im Wissenszentrum Ihres Teams oder in der Gesprächshistorie finden. Bitte formulieren Sie neu oder <a href="${this.addContentUrl}" target="_blank">fügen Sie Inhalte hinzu</a>, um Copilot zu helfen, mehr Fragen zu beantworten.`,
      el: `Το Copilot δεν μπόρεσε να βρει απάντηση στον κόμβο γνώσεων της ομάδας σας ή στο ιστορικό συνομιλιών. Παρακαλώ επαναδιατυπώστε ή <a href="${this.addContentUrl}" target="_blank">προσθέστε περιεχόμενο</a> για να βοηθήσετε το Copilot να απαντήσει σε περισσότερες ερωτήσεις.`,
      en: `Copilot couldn't find an answer in your team's knowledge hub or conversation history. Please rephrase or <a href="${this.addContentUrl}" target="_blank">add content</a> to help Copilot answer more questions.`,
      es: `Copilot no pudo encontrar una respuesta en el centro de conocimiento de su equipo o en el historial de conversaciones. Por favor, reformule o <a href="${this.addContentUrl}" target="_blank">agregue contenido</a> para ayudar a Copilot a responder más preguntas.`,
      et: `Copilot ei leidnud vastust teie meeskonna teadmistebaasist ega vestlusajaloost. Palun sõnastage ümber või <a href="${this.addContentUrl}" target="_blank">lisage sisu</a>, et aidata Copilotil rohkematele küsimustele vastata.`,
      'fa-IR': `Copilot نتوانست پاسخی در مرکز دانش تیم شما یا تاریخچه گفتگو پیدا کند. لطفاً دوباره عبارت را بازنویسی کنید یا <a href="${this.addContentUrl}" target="_blank">محتوا اضافه کنید</a> تا به Copilot کمک کنید به سؤالات بیشتری پاسخ دهد.`,
      fi: `Copilot ei löytänyt vastausta tiimisi tietokeskuksesta tai keskusteluhistoriasta. Ole hyvä ja muotoile uudelleen tai <a href="${this.addContentUrl}" target="_blank">lisää sisältöä</a> auttaaksesi Copilotia vastaamaan useampiin kysymyksiin.`,
      fr: `Copilot n\`a pas pu trouver de réponse dans le hub de connaissances de votre équipe ou l\`historique des conversations. Veuillez reformuler ou <a href="${this.addContentUrl}" target="_blank">ajouter du contenu</a> pour aider Copilot à répondre à plus de questions.`,
      he: `Copilot לא הצליח למצוא תשובה במרכז הידע של הצוות שלך או בהיסטוריית השיחות. אנא נסח מחדש או <a href="${this.addContentUrl}" target="_blank">הוסף תוכן</a> כדי לעזור ל-Copilot לענות על עוד שאלות.`,
      hi: `Copilot आपके टीम के ज्ञान केंद्र या वार्तालाप इतिहास में कोई उत्तर नहीं पा सका। कृपया पुनः वाक्य बनाएं या <a href="${this.addContentUrl}" target="_blank">Copilot को अधिक प्रश्नों का उत्तर देने में मदद करने के लिए सामग्री जोड़ें</a>।`,
      hr: `Copilot nije mogao pronaći odgovor u centru znanja vašeg tima ili povijesti razgovora. Molimo preformulirajte ili <a href="${this.addContentUrl}" target="_blank">dodajte sadržaj</a> kako biste pomogli Copilot odgovoriti na više pitanja.`,
      hu: `A Copilot nem talált választ a csapat tudásközpontjában vagy a beszélgetési előzményekben. Kérem, fogalmazza újra, vagy <a href="${this.addContentUrl}" target="_blank">adjon hozzá tartalmat</a>, hogy segítsen a Copilotnak több kérdésre válaszolni.`,
      id: `Copilot tidak dapat menemukan jawaban di pusat pengetahuan tim Anda atau riwayat percakapan. Silakan ubah atau <a href="${this.addContentUrl}" target="_blank">tambahkan konten</a> untuk membantu Copilot menjawab lebih banyak pertanyaan.`,
      it: `Copilot non è riuscito a trovare una risposta nell\`hub di conoscenza del tuo team o nella cronologia delle conversazioni. Si prega di riformulare o <a href="${this.addContentUrl}" target="_blank">aggiungere contenuti</a> per aiutare Copilot a rispondere a più domande.`,
      ja: `Copilotはチームのナレッジハブや会話履歴で回答が見つかりませんでした。再度言い換えるか、<a href="${this.addContentUrl}" target="_blank">コンテンツを追加</a>して、Copilotがさらに多くの質問に答えられるようにしてください。`,
      ko: `Copilot이 팀의 지식 허브나 대화 기록에서 답변을 찾을 수 없었습니다. 다시 작성하거나 <a href="${this.addContentUrl}" target="_blank">콘텐츠를 추가</a>하여 Copilot이 더 많은 질문에 답할 수 있도록 도와주세요.`,
      lt: `Copilot negalėjo rasti atsakymo jūsų komandos žinių centre ar pokalbių istorijoje. Prašome perfrazuoti arba <a href="${this.addContentUrl}" target="_blank">pridėti turinį</a>, kad padėtumėte Copilot atsakyti į daugiau klausimų.`,
      lv: `Copilot nevarēja atrast atbildi jūsu komandas zināšanu centrā vai sarunu vēsturē. Lūdzu, pārfrāzējiet vai <a href="${this.addContentUrl}" target="_blank">pievienojiet saturu</a>, lai palīdzētu Copilot atbildēt uz vairāk jautājumiem.`,
      mn: `Copilot таны багийн мэдлэгийн төв эсвэл ярианы түүхээс хариулт олж чадаагүй. Дахин бичнэ үү эсвэл <a href="${this.addContentUrl}" target="_blank">Copilot-д асуултанд хариулахад туслах агуулгыг нэмнэ үү</a>.`,
      ms: `Copilot tidak dapat mencari jawapan dalam hab pengetahuan pasukan anda atau sejarah perbualan. Sila olah semula atau <a href="${this.addContentUrl}" target="_blank">tambah kandungan</a> untuk membantu Copilot menjawab lebih banyak soalan.`,
      nb: `Copilot kunne ikke finne et svar i kunnskapshuben til teamet ditt eller samtalehistorikken. Vennligst omformuler eller <a href="${this.addContentUrl}" target="_blank">legg til innhold</a> for å hjelpe Copilot med å svare på flere spørsmål.`,
      nl: `Copilot kon geen antwoord vinden in het kenniscentrum van jouw team of in de gespreksgeschiedenis. Herformuleer het alstublieft of <a href="${this.addContentUrl}" target="_blank">voeg inhoud toe</a> om Copilot te helpen meer vragen te beantwoorden.`,
      pl: `Copilot nie mógł znaleźć odpowiedzi w centrum wiedzy Twojego zespołu ani w historii rozmów. Proszę przeformułuj lub <a href="${this.addContentUrl}" target="_blank">dodaj treść</a>, aby pomóc Copilotowi odpowiedzieć na więcej pytań.`,
      'pt-BR': `O Copilot não conseguiu encontrar uma resposta no hub de conhecimento da sua equipe ou no histórico de conversas. Por favor, reformule ou <a href="${this.addContentUrl}" target="_blank">adicione conteúdo</a> para ajudar o Copilot a responder mais perguntas.`,
      pt: `O Copilot não conseguiu encontrar uma resposta no hub de conhecimento da sua equipe ou no histórico de conversas. Por favor, reformule ou <a href="${this.addContentUrl}" target="_blank">adicione conteúdo</a> para ajudar o Copilot a responder mais perguntas.`,
      ro: `Copilot nu a putut găsi un răspuns în centrul de cunoștințe al echipei tale sau în istoricul conversațiilor. Vă rog să reformulați sau să <a href="${this.addContentUrl}" target="_blank">adăugați conținut</a> pentru a ajuta Copilot să răspundă la mai multe întrebări.`,
      ru: `Copilot не смог найти ответ в центре знаний вашей команды или в истории разговоров. Пожалуйста, переформулируйте или <a href="${this.addContentUrl}" target="_blank">добавьте контент</a>, чтобы помочь Copilot ответить на большее количество вопросов.`,
      sl: `Copilot ni mogel najti odgovora v vašem timu znanja ali zgodovini pogovorov. Prosimo, preoblikujte ali <a href="${this.addContentUrl}" target="_blank">dodajte vsebino</a> in pomagajte Copilot odgovoriti na več vprašanj.`,
      sr: `Copilot није могао да пронађе одговор у центру знања вашег тима или у историји разговора. Молимо вас да преобликујете или <a href="${this.addContentUrl}" target="_blank">додате садржај</a> како бисте помогли Copilot-у да одговори на више питања.`,
      sv: `Copilot kunde inte hitta ett svar i ditt teams kunskapsnav eller samtalshistorik. Vänligen omformulera eller <a href="${this.addContentUrl}" target="_blank">lägg till innehåll</a> för att hjälpa Copilot att svara på fler frågor.`,
      sw: `Copilot hakuweza kupata jibu katika kituo cha maarifa cha timu yako au historia ya mazungumzo. Tafadhali rejesha upya au <a href="${this.addContentUrl}" target="_blank">ongeza maudhui</a> ili kusaidia Copilot kujibu maswali zaidi.`,
      th: `Copilot ไม่พบคำตอบในศูนย์ความรู้ของทีมคุณหรือประวัติการสนทนา กรุณาเขียนใหม่หรือ <a href="${this.addContentUrl}" target="_blank">เพิ่มเนื้อหา</a> เพื่อช่วยให้ Copilot ตอบคำถามเพิ่มเติมได้`,
      tr: `Copilot ekibinizin bilgi merkezinde veya konuşma geçmişinde bir cevap bulamadı. Lütfen yeniden ifade edin veya daha fazla soruya yanıt vermesine yardımcı olmak için <a href="${this.addContentUrl}" target="_blank">içerik ekleyin</a>.`,
      uk: `Copilot не зміг знайти відповідь у центрі знань вашої команди або в історії розмов. Будь ласка, переформулюйте або <a href="${this.addContentUrl}" target="_blank">додайте вміст</a>, щоб допомогти Copilot відповісти на більше запитань.`,
      vi: `Copilot không thể tìm thấy câu trả lời trong trung tâm kiến thức của đội bạn hoặc lịch sử cuộc trò chuyện. Vui lòng diễn đạt lại hoặc <a href="${this.addContentUrl}" target="_blank">thêm nội dung</a> để giúp Copilot trả lời thêm nhiều câu hỏi.`,
      'zh-CN': `Copilot在您的团队知识中心或对话历史中找不到答案。请重新表述或<a href="${this.addContentUrl}" target="_blank">添加内容</a>以帮助Copilot回答更多问题。`,
      'zh-TW': `Copilot在您的團隊知識中心或對話歷史中找不到答案。請重新表述或<a href="${this.addContentUrl}" target="_blank">添加內容</a>以幫助Copilot回答更多問題。`,
      zh: `Copilot在您的团队知识中心或对话历史中找不到答案。请重新表述或<a href="${this.addContentUrl}" target="_blank">添加内容</a>以帮助Copilot回答更多问题。`,
      fa: `Copilot نتوانست پاسخی در مرکز دانش تیم شما یا تاریخچه گفتگو پیدا کند. لطفاً دوباره عبارت را بازنویسی کنید یا <a href="${this.addContentUrl}" target="_blank">محتوا اضافه کنید</a> تا به Copilot کمک کنید به سؤالات بیشتری پاسخ دهد.`,
    };

    /* eslint-enable @intercom/intercom/no-bare-strings */
  }

  get noContentDescriptionTranslations() {
    // We need to hardcode these translations because we're only translating 5 languages
    // in the Inbox, and Copilot multilingual can chat in 47 languages. We don't want to
    // translate too much more, but this is actual copy in an answer.
    /* eslint-disable @intercom/intercom/no-bare-strings */
    return {
      ar: 'يبدو أن فريقك لم يضف أي محتوى دعم إلى مركز المعرفة الخاص بك حتى الآن. يحتاج Copilot إلى مصادر لمساعدتي في الإجابة على أسئلتك.',
      bg: 'Изглежда, че вашият екип все още не е добавил поддържащо съдържание към вашия център за знания. Copilot се нуждае от източници, за да отговори на въпросите ви.',
      bn: 'আপনার দলের সদস্যরা এখনও আপনার জ্ঞান কেন্দ্রে কোনও সহায়তামূলক কনটেন্ট যোগ করেনি বলে মনে হচ্ছে। Copilot-এর আপনার প্রশ্নের উত্তর দিতে উৎস প্রয়োজন।',
      bs: 'Izgleda da vaš tim još nije dodao podršku u vaš centar znanja. Copilot treba izvore da bi vam mogao odgovoriti na pitanja.',
      ca: 'Sembla que el vostre equip encara no ha afegit cap contingut de suport al vostre centre de coneixement. Copilot necessita fonts per ajudar a respondre les vostres preguntes.',
      cs: 'Vypadá to, že váš tým dosud nepřidal žádný podpůrný obsah do vašeho znalostního centra. Copilot potřebuje zdroje, aby mohl odpovědět na vaše otázky.',
      da: 'Det ser ud til, at dit team endnu ikke har tilføjet noget supportindhold til din videnshub. Copilot har brug for kilder til at besvare dine spørgsmål.',
      de: 'Es sieht so aus, als hätte Ihr Team noch keine Unterstützungsinhalte zu Ihrem Wissenshub hinzugefügt. Copilot benötigt Quellen, um Ihre Fragen zu beantworten.',
      'de-form':
        'Es scheint, dass Ihr Team noch keine Unterstützungsinhalte zu Ihrem Wissenshub hinzugefügt hat. Copilot benötigt Quellen, um Ihre Fragen zu beantworten.',
      el: 'Φαίνεται ότι η ομάδα σας δεν έχει προσθέσει ακόμη περιεχόμενο υποστήριξης στο κέντρο γνώσεων σας. Το Copilot χρειάζεται πηγές για να απαντήσει στις ερωτήσεις σας.',
      en: "It looks like your team hasn't added any support content to your knowledge hub yet. Copilot needs sources to help me answer your questions.",
      es: 'Parece que su equipo aún no ha añadido contenido de soporte a su centro de conocimiento. Copilot necesita fuentes para ayudar a responder sus preguntas.',
      et: 'Tundub, et teie meeskond pole veel lisanud tugisisu teie teadmistekeskusesse. Copilot vajab allikaid, et aidata teie küsimustele vastata.',
      'fa-IR':
        'به نظر می‌رسد تیم شما هنوز هیچ محتوای پشتیبانی به مرکز دانش شما اضافه نکرده است. Copilot به منابعی نیاز دارد تا به سؤالات شما پاسخ دهد.',
      fi: 'Näyttää siltä, että tiimisi ei ole vielä lisännyt tukisisältöä tietokeskukseen. Copilot tarvitsee lähteitä auttaakseen vastaamaan kysymyksiisi.',
      fr: "Il semble que votre équipe n'ait pas encore ajouté de contenu de support à votre centre de connaissances. Copilot a besoin de sources pour répondre à vos questions.",
      he: 'נראה כי הצוות שלך עדיין לא הוסיף תוכן תמיכה למרכז הידע שלך. Copilot צריך מקורות כדי לענות על השאלות שלך.',
      hi: 'ऐसा लगता है कि आपकी टीम ने अभी तक आपके ज्ञान केंद्र में कोई समर्थन सामग्री नहीं जोड़ी है। Copilot को आपके प्रश्नों का उत्तर देने के लिए स्रोतों की आवश्यकता है।',
      hr: 'Čini se da vaš tim još nije dodao sadržaj podrške u vaš centar znanja. Copilot treba izvore da bi vam mogao odgovoriti na pitanja.',
      hu: 'Úgy tűnik, hogy a csapata még nem adott hozzá támogató tartalmat a tudásközpontjához. A Copilotnak forrásokra van szüksége, hogy megválaszolhassa a kérdéseit.',
      id: 'Sepertinya tim Anda belum menambahkan konten dukungan ke pusat pengetahuan Anda. Copilot membutuhkan sumber daya untuk membantu menjawab pertanyaan Anda.',
      it: 'Sembra che il tuo team non abbia ancora aggiunto contenuti di supporto al tuo hub di conoscenza. Copilot ha bisogno di fonti per rispondere alle tue domande.',
      ja: 'あなたのチームはまだナレッジハブにサポートコンテンツを追加していないようです。Copilotは質問に答えるために情報源が必要です。',
      ko: '팀에서 아직 지식 허브에 지원 콘텐츠를 추가하지 않은 것 같습니다. Copilot이 질문에 답변하기 위해 출처가 필요합니다.',
      lt: 'Panašu, kad jūsų komanda dar nepridėjo jokio palaikymo turinio į jūsų žinių centrą. Copilot reikia šaltinių, kad galėtų atsakyti į jūsų klausimus.',
      lv: 'Izskatās, ka jūsu komanda vēl nav pievienojusi atbalsta saturu jūsu zināšanu centram. Copilot ir nepieciešami avoti, lai atbildētu uz jūsu jautājumiem.',
      mn: 'Танай баг мэдлэгийн төвдөө дэмжлэгийн агуулга нэмээгүй бололтой. Copilot-д таны асуултанд хариулахад эх сурвалжууд хэрэгтэй байна.',
      ms: 'Nampaknya pasukan anda belum menambah kandungan sokongan ke pusat pengetahuan anda lagi. Copilot perlukan sumber untuk membantu menjawab soalan anda.',
      nb: 'Det ser ut som teamet ditt ennå ikke har lagt til noe støtteinnhold i kunnskapshuben din. Copilot trenger kilder for å svare på spørsmålene dine.',
      nl: 'Het lijkt erop dat uw team nog geen ondersteuningsinhoud heeft toegevoegd aan uw kenniscentrum. Copilot heeft bronnen nodig om uw vragen te beantwoorden.',
      pl: 'Wygląda na to, że Twój zespół nie dodał jeszcze żadnych treści wspierających do Twojego centrum wiedzy. Copilot potrzebuje źródeł, aby odpowiedzieć na Twoje pytania.',
      'pt-BR':
        'Parece que sua equipe ainda não adicionou nenhum conteúdo de suporte ao seu hub de conhecimento. O Copilot precisa de fontes para ajudar a responder às suas perguntas.',
      pt: 'Parece que a sua equipe ainda não adicionou nenhum conteúdo de suporte ao seu centro de conhecimento. O Copilot precisa de fontes para ajudar a responder às suas perguntas.',
      ro: 'Se pare că echipa ta nu a adăugat încă niciun conținut de suport în centrul tău de cunoștințe. Copilot are nevoie de surse pentru a răspunde la întrebările tale.',
      ru: 'Похоже, ваша команда еще не добавила никакого поддерживающего контента в ваш информационный центр. Copilot нужны источники, чтобы ответить на ваши вопросы.',
      sl: 'Zdi se, da vaša ekipa še ni dodala nobene vsebine za podporo v vašo zbirko znanja. Copilot potrebuje vire za odgovarjanje na vaša vprašanja.',
      sr: 'Izgleda da vaš tim još nije dodao podršku u vaš centar znanja. Copilot treba izvore da bi vam mogao odgovoriti na pitanja.',
      sv: 'Det verkar som att ditt team ännu inte har lagt till något supportinnehåll till din kunskapshubb. Copilot behöver källor för att besvara dina frågor.',
      sw: 'Inaonekana timu yako bado haijaongeza maudhui yoyote ya usaidizi kwenye kitovu chako cha maarifa. Copilot anahitaji vyanzo vya kujibu maswali yako.',
      th: 'ดูเหมือนว่าทีมของคุณยังไม่ได้เพิ่มเนื้อหาการสนับสนุนใด ๆ ลงในศูนย์ความรู้ของคุณ Copilot ต้องการแหล่งข้อมูลเพื่อช่วยตอบคำถามของคุณ',
      tr: 'Görünüşe göre ekibiniz henüz bilgi merkezine herhangi bir destek içeriği eklememiş. Copilot, sorularınızı yanıtlamak için kaynaklara ihtiyaç duyuyor.',
      uk: 'Схоже, ваша команда ще не додала жодного матеріалу підтримки до вашого центру знань. Copilot потребує джерел, щоб відповісти на ваші запитання.',
      vi: 'Có vẻ như đội của bạn chưa thêm bất kỳ nội dung hỗ trợ nào vào trung tâm kiến thức của bạn. Copilot cần các nguồn để trả lời câu hỏi của bạn.',
      'zh-CN':
        '看起来您的团队还没有向您的知识中心添加任何支持内容。Copilot需要资源来回答您的问题。',
      'zh-TW': '看起來您的團隊尚未在您的知識中心添加任何支援內容。Copilot需要資源來回答您的問題。',
      zh: '看起来您的团队还没有向您的知识中心添加任何支持内容。Copilot需要资源来回答您的问题。',
      fa: 'به نظر می‌رسد تیم شما هنوز هیچ محتوای پشتیبانی به مرکز دانش شما اضافه نکرده است. Copilot به منابعی نیاز دارد تا به سؤالات شما پاسخ دهد.',
    };
    /* eslint-enable @intercom/intercom/no-bare-strings */
  }

  get buttonTranslations(): Record<string, Record<string, string>> {
    // We need to hardcode these translations because we're only translating 5 languages
    // in the Inbox, and Copilot multilingual can chat in 47 languages. We don't want to
    // translate too much more, but this is actual copy in an answer.
    /* eslint-disable @intercom/intercom/no-bare-strings */
    return {
      ar: {
        'learn-more': 'تعرف على المزيد',
        'add-content': 'أضف محتوى',
      },
      bg: {
        'learn-more': 'Научете повече',
        'add-content': 'Добавете съдържание',
      },
      bn: {
        'learn-more': 'আরও জানুন',
        'add-content': 'বিষয়বস্তু যোগ করুন',
      },
      bs: {
        'learn-more': 'Saznaj više',
        'add-content': 'Dodaj sadržaj',
      },
      ca: {
        'learn-more': 'Més informació',
        'add-content': 'Afegeix contingut',
      },
      cs: {
        'learn-more': 'Zjistit více',
        'add-content': 'Přidat obsah',
      },
      da: {
        'learn-more': 'Lær mere',
        'add-content': 'Tilføj indhold',
      },
      de: {
        'learn-more': 'Erfahren Sie mehr',
        'add-content': 'Inhalt hinzufügen',
      },
      'de-form': {
        'learn-more': 'Erfahren Sie mehr',
        'add-content': 'Inhalt hinzufügen',
      },
      el: {
        'learn-more': 'Μάθετε περισσότερα',
        'add-content': 'Προσθήκη περιεχομένου',
      },
      en: {
        'learn-more': 'Learn more',
        'add-content': 'Add content',
      },
      es: {
        'learn-more': 'Aprende más',
        'add-content': 'Agregar contenido',
      },
      et: {
        'learn-more': 'Lisateave',
        'add-content': 'Lisa sisu',
      },
      'fa-IR': {
        'learn-more': 'بیشتر بدانید',
        'add-content': 'اضافه کردن محتوا',
      },
      fi: {
        'learn-more': 'Lue lisää',
        'add-content': 'Lisää sisältöä',
      },
      fr: {
        'learn-more': 'En savoir plus',
        'add-content': 'Ajouter du contenu',
      },
      he: {
        'learn-more': 'למד עוד',
        'add-content': 'הוסף תוכן',
      },
      hi: {
        'learn-more': 'और जानें',
        'add-content': 'सामग्री जोड़ें',
      },
      hr: {
        'learn-more': 'Saznajte više',
        'add-content': 'Dodaj sadržaj',
      },
      hu: {
        'learn-more': 'Tudj meg többet',
        'add-content': 'Tartalom hozzáadása',
      },
      id: {
        'learn-more': 'Pelajari lebih lanjut',
        'add-content': 'Tambahkan konten',
      },
      it: {
        'learn-more': 'Scopri di più',
        'add-content': 'Aggiungi contenuto',
      },
      ja: {
        'learn-more': 'もっと詳しく知る',
        'add-content': 'コンテンツを追加',
      },
      ko: {
        'learn-more': '자세히 알아보기',
        'add-content': '콘텐츠 추가',
      },
      lt: {
        'learn-more': 'Sužinoti daugiau',
        'add-content': 'Pridėti turinį',
      },
      lv: {
        'learn-more': 'Uzzināt vairāk',
        'add-content': 'Pievienot saturu',
      },
      mn: {
        'learn-more': 'Дэлгэрэнгүй үзэх',
        'add-content': 'Агуулга нэмэх',
      },
      ms: {
        'learn-more': 'Ketahui lebih lanjut',
        'add-content': 'Tambah kandungan',
      },
      nb: {
        'learn-more': 'Lær mer',
        'add-content': 'Legg til innhold',
      },
      nl: {
        'learn-more': 'Meer informatie',
        'add-content': 'Inhoud toevoegen',
      },
      pl: {
        'learn-more': 'Dowiedz się więcej',
        'add-content': 'Dodaj treść',
      },
      'pt-BR': {
        'learn-more': 'Saiba mais',
        'add-content': 'Adicionar conteúdo',
      },
      pt: {
        'learn-more': 'Saber mais',
        'add-content': 'Adicionar conteúdo',
      },
      ro: {
        'learn-more': 'Află mai multe',
        'add-content': 'Adăugați conținut',
      },
      ru: {
        'learn-more': 'Узнать больше',
        'add-content': 'Добавить контент',
      },
      sl: {
        'learn-more': 'Izvedi več',
        'add-content': 'Dodaj vsebino',
      },
      sr: {
        'learn-more': 'Saznajte više',
        'add-content': 'Dodaj sadržaj',
      },
      sv: {
        'learn-more': 'Läs mer',
        'add-content': 'Lägg till innehåll',
      },
      sw: {
        'learn-more': 'Jifunze zaidi',
        'add-content': 'Ongeza maudhui',
      },
      th: {
        'learn-more': 'เรียนรู้เพิ่มเติม',
        'add-content': 'เพิ่มเนื้อหา',
      },
      tr: {
        'learn-more': 'Daha fazla bilgi edinin',
        'add-content': 'İçerik ekle',
      },
      uk: {
        'learn-more': 'Дізнатися більше',
        'add-content': 'Додати контент',
      },
      vi: {
        'learn-more': 'Tìm hiểu thêm',
        'add-content': 'Thêm nội dung',
      },
      'zh-CN': {
        'learn-more': '了解更多',
        'add-content': '添加内容',
      },
      'zh-TW': {
        'learn-more': '了解更多',
        'add-content': '新增內容',
      },
      zh: {
        'learn-more': '了解更多',
        'add-content': '添加内容',
      },
      fa: {
        'learn-more': 'بیشتر بدانید',
        'add-content': 'اضافه کردن محتوا',
      },
    };
    /* eslint-enable @intercom/intercom/no-bare-strings */
  }

  get learnMoreButtonText() {
    let locale = this.args.message.metadata?.locale;

    if (typeof locale !== 'string' || isEmpty(locale) || !(locale in this.buttonTranslations)) {
      return this.intl.t('inbox.ask-fin.related-contents.fallback-state-learn-more-button');
    }

    return this.buttonTranslations[locale]['learn-more'];
  }

  get addContentButtonText() {
    let locale = this.args.message.metadata?.locale;

    if (typeof locale !== 'string' || isEmpty(locale) || !(locale in this.buttonTranslations)) {
      return this.intl.t('inbox.ask-fin.related-contents.fallback-state-add-content-button');
    }

    return this.buttonTranslations[locale]['add-content'];
  }

  get noAnswerDescriptionText() {
    let locale = this.args.message.metadata?.locale;

    if (
      typeof locale !== 'string' ||
      isEmpty(locale) ||
      !(locale in this.descriptionTranslations)
    ) {
      return this.intl.t('inbox.ask-fin.no-answer-description', {
        addContentUrl: this.addContentUrl,
      });
    }

    return this.descriptionTranslations[locale as keyof typeof this.descriptionTranslations];
  }

  get noContentNoAnswerDescriptionText() {
    let locale = this.args.message.metadata?.locale;

    if (
      typeof locale !== 'string' ||
      isEmpty(locale) ||
      !(locale in this.noContentDescriptionTranslations)
    ) {
      return this.intl.t('inbox.ask-fin.related-contents.fallback-state-no-content');
    }

    return this.noContentDescriptionTranslations[
      locale as keyof typeof this.noContentDescriptionTranslations
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::NoAnswer': typeof NoAnswer;
    'inbox2/copilot/message-states/no-answer': typeof NoAnswer;
  }
}
