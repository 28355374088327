/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Block } from '@intercom/interblocks.ts';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import type ConvertToTicketAction from 'embercom/objects/inbox/command-k/convert-to-ticket';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import type CommandKService from 'embercom/services/command-k';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type Snackbar from 'embercom/services/snackbar';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import PredicateGroup, {
  type Predicate,
  type SimplePredicate,
} from 'embercom/objects/inbox/search/predicate-group';
import { type SortParams } from 'embercom/services/inbox-api';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { cancel, debounce } from '@ember/runloop';
import { Channel, CHAT_CHANNELS } from 'embercom/models/data/inbox/channels';

interface Signature {
  Args: CommandKPaneComponentArgs<ConvertToTicketAction>;
}

interface renderableDataType extends RenderableData {
  blocks?: Block[] | undefined;
}

const CROSS_USER_MERGEABLE_CHANNELS = [Channel.Email, Channel.PhoneCall];

export default class MergeIntoPaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @service declare snackbar: Snackbar;

  @tracked query = '';
  @tracked isPreviewModalOpen = false;
  @tracked isMergeConfirmationModalOpen = false;

  searchDebounce?: any;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.latestConversations,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get conversation() {
    return this.inboxState.activeConversation as Conversation;
  }

  get isCrossUserMergeEnabled(): boolean {
    return (
      this.session.workspace.isFeatureEnabled('merge-conversations-cross-user') &&
      this.session.workspace.enabledCrossUserConversationMerge
    );
  }

  get latestConversations() {
    if (this.useCrossUserSearch) {
      return this.latestConversationsLoaderCrossUser.value ?? [];
    } else {
      let latestConversations = this.latestConversationsLoader.value;

      if (!latestConversations) {
        return [];
      }

      let filteredConversations = this.query
        ? this.sortLatestConversations(latestConversations).filter((conversation) =>
            this.isConversationMatchQuery(conversation, this.query),
          )
        : this.sortLatestConversations(latestConversations);

      return filteredConversations;
    }
  }

  get useCrossUserSearch() {
    return (
      this.isCrossUserMergeEnabled &&
      CROSS_USER_MERGEABLE_CHANNELS.includes(this.conversation?.channel?.initial)
    );
  }

  @action hide() {
    this.commandK.hide();
    this.isPreviewModalOpen = false;
    this.isMergeConfirmationModalOpen = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'exit',
      object: 'commandk_merge_modal',
      section: 'composer',
    });
  }

  @action onShowMergeConfirmationModal() {
    this.isPreviewModalOpen = false;
    this.isMergeConfirmationModalOpen = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'show',
      object: 'confirmation_merge_preview_modal_warning',
      section: 'conversation_merge',
    });
  }

  @action onCloseMergeConfirmationModal() {
    this.isMergeConfirmationModalOpen = false;
    this.isPreviewModalOpen = true;
  }

  @action onCloseAllModals() {
    this.commandK.hide();
    this.isMergeConfirmationModalOpen = false;
    this.isPreviewModalOpen = false;
  }

  private isConversationMatchQuery(
    conversation: LatestConversationSummary,
    query: string,
  ): boolean {
    let lowerCaseTitle = conversation.title?.toLowerCase() || '';
    let lowerCaseQuery = query.toLowerCase();
    let renderableData = conversation.lastRenderableSummaryPart
      ?.renderableData as renderableDataType;
    let blockData: any = renderableData?.blocks?.firstObject;
    let conversationId = conversation.id.toString();
    let ticketId = conversation.ticketId?.toString();
    let textToSearch = (blockData && blockData.text?.toLowerCase()) || '';
    return (
      textToSearch.includes(lowerCaseQuery) ||
      lowerCaseTitle.includes(lowerCaseQuery) ||
      conversationId.includes(lowerCaseQuery) ||
      ticketId?.includes(lowerCaseQuery)
    );
  }

  get limitedLatestConversations() {
    if (this.isCrossUserMergeEnabled) {
      return this.latestConversations;
    } else {
      return this.latestConversations.slice(0, 5);
    }
  }

  latestConversationsLoader = DeduplicatedAsyncData(
    this,
    () => [this.conversation?.user.id, this.conversation?.id],
    async (userID, conversationID, { signal }) => {
      let { conversations } = await this.inboxApi.getUserConversations(userID, conversationID, {
        signal,
      });

      return conversations.filter((convo) => {
        return (
          (!this.session.workspace.isFeatureEnabled('channels-merge-conversations-show-closed')
            ? convo.state !== ConversationState.Closed
            : true) &&
          convo.participantSummaries.length <= 1 &&
          !convo.redacted &&
          (this.conversation.isTicket ? convo.isTicket : true)
        );
      });
    },
  );

  private getParticipantIds(conversation: Conversation | LatestConversationSummary): Set<string> {
    return new Set(conversation.participantSummaries?.map((p) => p.id) ?? []);
  }

  get hasDifferentParticipants(): boolean {
    if (!this.selection.selected) {
      return false;
    }

    let primaryIds = this.getParticipantIds(this.selection.selected);
    let secondaryIds = this.getParticipantIds(this.conversation);

    // If either conversation has no participants, treat them as different
    if (primaryIds.size === 0 || secondaryIds.size === 0) {
      return true;
    }

    // Check if the sets have different sizes or any non-matching elements
    return (
      primaryIds.size !== secondaryIds.size || [...primaryIds].some((id) => !secondaryIds.has(id))
    );
  }

  latestConversationsLoaderCrossUser = DeduplicatedAsyncData(
    this,
    () => [this.query, this.conversation?.channel?.initial],
    async (query, conversationInitialChannel) => {
      // Define which channels can be merged based on the initial channel
      let allowedChannels: Channel[] = [];
      if (conversationInitialChannel === Channel.Email) {
        allowedChannels = [Channel.Email, ...CHAT_CHANNELS];
      } else if (conversationInitialChannel === Channel.PhoneCall) {
        allowedChannels = [Channel.Email, ...CHAT_CHANNELS, Channel.PhoneCall];
      }

      // Create a predicate for allowed channels
      let predicates: Predicate[] = [
        {
          identifier: 'initial-channel',
          type: 'or',
          predicates: allowedChannels.map(
            (channel) =>
              ({
                identifier: `initial-channel`,
                type: 'integer',
                attribute: 'initial_channel',
                comparison: 'eq',
                value: channel,
              }) as SimplePredicate,
          ),
        },
      ];

      // If the query is a number, search conversation and ticket ids
      if (query && !isNaN(parseInt(query, 10))) {
        predicates.push({
          identifier: 'conversation-or-ticket-id',
          type: 'or',
          predicates: [
            {
              identifier: 'id',
              type: 'id',
              attribute: 'id',
              comparison: 'eq',
              value: query,
            } as SimplePredicate,
            {
              identifier: 'ticket-id',
              type: 'id',
              attribute: 'ticket_id',
              comparison: 'eq',
              value: query,
            } as SimplePredicate,
          ],
        });
      }

      let predicatesGroup = new PredicateGroup(predicates);

      let searchParams = {
        query,
        sortParams: {
          sort_field: 'relevance',
          sort_direction: 'desc',
        } as SortParams,
        count: 10,
        predicates: { predicates: predicatesGroup.predicates },
        fields: [
          'last_renderable_part',
          'ticket_type',
          'ticket_id',
          'ticket_custom_state_id',
          'title',
          'last_updated',
        ],
        searchSource: 'merge-into-command-k',
      };
      let { conversations } = await this.inboxApi.searchForConversationsTableV2(searchParams);
      let conversationsExcludingCurrent = conversations.filter(
        (conversation: ConversationTableEntry) => conversation.id !== this.conversation.id,
      );
      return conversationsExcludingCurrent;
    },
  );

  private sortLatestConversations(
    conversations: LatestConversationSummary[],
  ): LatestConversationSummary[] {
    return [...conversations].sort(
      (conv1: LatestConversationSummary, conv2: LatestConversationSummary) =>
        conv2.lastUpdated.getTime() - conv1.lastUpdated.getTime(),
    );
  }

  @action search(query: string) {
    if (this.searchDebounce) {
      cancel(this.searchDebounce);
    }
    this.searchDebounce = debounce(this, this.setQuery, query, 150);

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.latestConversations.length,
    });
  }

  setQuery(query: string) {
    this.query = query;
  }

  @action async mergeConversation(
    primaryConversationSummary: ConversationRecord,
    secondaryConversationSummary: ConversationRecord,
  ) {
    await this.inboxApi.mergeConversation(
      secondaryConversationSummary.id,
      primaryConversationSummary.id,
    );
    let { isTicket, title, id, ticketId } = primaryConversationSummary;

    let type = isTicket
      ? this.intl.t('inbox.merge-into.ticket-type')
      : this.intl.t('inbox.merge-into.conversation-type');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'confirm',
      object: 'confirmation_merge_preview_modal',
      section: 'conversation_merge',
    });

    this.snackbar.notify(
      this.intl.t('inbox.merge-into.merged-conversation', {
        type,
        title,
        id: isTicket ? ticketId : id,
      }),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::MergeInto::Pane': typeof MergeIntoPaneComponent;
    'inbox2/command-k/merge-into/pane': typeof MergeIntoPaneComponent;
  }
}
