/* import __COLOCATED_TEMPLATE__ from './conversation-preview-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';

import { ConversationResource } from './conversation-resource';
import SmartReplyResource from './smart-reply-resource';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation } from 'embercom/services/inbox-state';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import { next } from '@ember/runloop';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { type NexusEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type IntlService from 'embercom/services/intl';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type Router from '@ember/routing/router-service';
import { type SendAndCloseFn } from 'embercom/objects/inbox/types/composer';
import type EmojiService from 'embercom/services/emoji-service';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import type InboxApi from 'embercom/services/inbox-api';
import { use } from 'ember-resources/util/function-resource';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  conversationId: number;
  shouldFocusComposer?: boolean;
  resetShouldFocusComposer?: () => void;
  isTableView?: boolean;
  isNotePaneActive: boolean;
  onSendAndClose?: SendAndCloseFn;
  defaultTab?: string;
  expandedSection?: SupportedSidebarSection | number;
}

interface Signature {
  Args: Args;
}

type FocusFunction = () => void;

export default class ConversationPreviewPanel extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare nexus: Nexus;
  @service declare intl: IntlService;
  @service declare conversationStreamSettings: ConversationStreamSettings;
  @service declare router: Router;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare emojiService: EmojiService;
  @service declare inboxApi: InboxApi;

  @tracked conversationId: number = this.args.conversationId;
  @tracked composerFocusFn?: FocusFunction;

  @tracked ticketTypeId?: number;
  @tracked selectedTab = this.args.defaultTab || 'conversation';

  @trackedReset({
    memo: 'args.shouldFocusComposer',
    update(component: ConversationPreviewPanel, _: string, last: boolean) {
      // When shouldFocusComposer changes, we'll recalculate the composer
      // visibility.

      // However, if the composer was already visible, we don't change its
      // visibility. If it wasn't visible and shouldFocusComposer became true,
      // then we make it visible.
      if (last) {
        return last;
      }

      return component.args.shouldFocusComposer;
    },
  })
  isComposerVisible = this.args.shouldFocusComposer ?? false;

  @ref('preview-panel-composer-container') declare composerContainer: HTMLElement;
  @ref('preview-panel-composer-opener') declare composerOpener: HTMLElement;

  readonly defaultInbox = { id: 'all', category: 'shared' };
  readonly notePane = ComposerPaneType.Note;
  readonly replyPane = ComposerPaneType.Reply;
  readonly ComposerLocation = ComposerLocation;
  readonly toggleEventKey: Hotkey;

  // We need to set a higher keyboard priority for the command-k actions within
  // the preview panel so that command-k targets the conversation in the preview
  // panel instead of the active conversation.
  readonly keyboardPriority = 1;

  conversationResource = ConversationResource.from(this, () => ({
    conversationId: this.args.conversationId,
  }));

  smartReplyResource = SmartReplyResource.from(this, () => ({
    conversation: this.conversationResource.conversation,
  }));

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.expandedSection) {
      this.selectedTab = 'ticketAttributes';
    }

    this.toggleEventKey = this.inboxHotkeys.hotkeysMap[HotkeyID.ToggleEvents];
  }

  willDestroy(): void {
    super.willDestroy();
    this.toggleMessengerVisibility({ hide: false });
  }

  @action
  handleThreadUpdatedEvent(e: NexusEvent) {
    if (Number(e.eventData.conversationId) === this.conversationId) {
      this.reloadConversation();
    }
  }

  get conversation(): Conversation {
    return this.conversationResource.conversation;
  }

  get hasError(): boolean {
    return this.conversationResource.hasError;
  }

  get hasTicketTypeToAddToConversation() {
    return this.ticketTypeId !== undefined;
  }

  get showDetailsTab() {
    return !this.conversation.isRedacted;
  }

  get showLinkedItemsCount(): boolean {
    if (this.conversation.isChild) {
      return false;
    }

    if (!this.args.isTableView) {
      return false;
    }

    return this.conversation.linkedCustomerReportIds.length > 0;
  }

  get showParentTicket() {
    return this.conversation.isChild;
  }

  get parentTicketName() {
    return this.conversation.parent?.ticketType?.name || this.intl.t('inbox.conversation');
  }

  get parentUserName() {
    return this.conversation.parent?.userSummary?.displayAs;
  }

  get hasTrackerTickets() {
    if (!this.linkedTickets.value) {
      return false;
    }

    let trackerTickets = this.linkedTickets.value.filter(
      (ticket) => ticket.category === TicketCategory.Tracker,
    );

    return trackerTickets.length > 0;
  }

  get hasSharedTaskTickets() {
    if (!this.linkedTickets.value) {
      return false;
    }

    return this.linkedTickets.value
      .filter((ticket) => ticket.category === TicketCategory.Task)
      .some((taskTickets) => taskTickets.visibleToUser);
  }

  get hasLinkedTicket() {
    return this.hasSharedTaskTickets || this.hasTrackerTickets;
  }

  get canConvertToTicket() {
    return !this.conversation?.isTicket && !this.hasLinkedTicket;
  }

  @action goToParent() {
    let parentConversationId = this.conversation.parent?.id;
    if (parentConversationId) {
      this.router.transitionTo(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        parentConversationId,
      );
    }
  }

  @action onReady({ focus }: { focus: FocusFunction }) {
    this.composerFocusFn = focus;

    if (this.args.shouldFocusComposer) {
      focus();
      this.args.resetShouldFocusComposer?.();
    }
  }

  sendAndClose: SendAndCloseFn = async (blocks, macroActions, _, replyData, recipients) => {
    if (this.args.onSendAndClose) {
      this.args.onSendAndClose(blocks, macroActions, undefined, replyData, recipients);
    } else {
      this.conversationResource.sendAndClose(
        blocks,
        macroActions,
        undefined,
        replyData,
        recipients,
      );
    }
  };

  @action showComposer() {
    this.isComposerVisible = true;
    this.toggleMessengerVisibility({ hide: true });
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPreviewPanel);

    next(() => this.composerFocusFn?.());
  }

  @action hideComposer() {
    this.isComposerVisible = false;
    this.toggleMessengerVisibility({ hide: false });
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPage);
    setTimeout(() => this.composerOpener.focus(), 20);
  }

  @action handleDocumentClick(event: PointerEvent) {
    if (!this.isComposerVisible || !event.target) {
      return;
    }

    let ignoredElements = [
      this.composerContainer,
      document.querySelector('.popover__content'),
      document.querySelector('.inbox2__command-k-modal'),
      document.querySelector('.prosemirror-composer-popover-container'),
    ].compact() as HTMLElement[];

    let ignoreClick = ignoredElements.any((element) =>
      element.contains(event.target as HTMLElement),
    );
    if (ignoreClick) {
      return;
    }

    this.hideComposer();
  }

  private toggleMessengerVisibility({ hide }: { hide: boolean }) {
    if ('Intercom' in window) {
      // @ts-ignore
      window.Intercom('update', {
        hide_default_launcher: hide,
      });
    }
  }

  @action reloadConversation() {
    this.conversationResource.reload();
  }

  @action handleToggleEventsKey(e: KeyboardEvent, emberEvent: any) {
    if (this.isComposerVisible) {
      e.preventDefault();
      emberEvent.stopPropagation();
      emberEvent.stopImmediatePropagation();
      this.conversationStreamSettings.toggleEvents();
    }
  }

  @action setTicketTypeToAddToConversation(ticketTypeId?: number) {
    this.ticketTypeId = ticketTypeId;
    this.selectedTab = 'ticketAttributes';
  }

  get hideEvents() {
    return this.conversationStreamSettings.hideEvents;
  }

  @action onToggleConversationEvents() {
    this.selectedTab = 'stream';
    this.conversationStreamSettings.toggleEvents();
  }

  @use linkedTickets = AsyncData<LinkedTicketSummary[]>(async () => {
    if (!this.conversation) {
      return false;
    }
    let { tickets } = await this.inboxApi.getLinkedTickets(this.conversation.id);
    return tickets;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPreviewPanel': typeof ConversationPreviewPanel;
    'inbox2/conversation-preview-panel': typeof ConversationPreviewPanel;
  }
}
