/* import __COLOCATED_TEMPLATE__ from './fin-customisation-settings-applied.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type FinCustomisationSettingsApplied from 'embercom/objects/inbox/renderable/fin-customisation-settings-applied';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';

interface FinCustomisationSettingsAppliedPart extends RenderablePart {
  renderableData: FinCustomisationSettingsApplied;
}

interface Args {
  part: FinCustomisationSettingsAppliedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsFinCustomisationSettingsAppliedComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  standaloneGuidancePage = 'apps.app.standalone.guidance';
  guidancePage = 'apps.app.automation.fin-ai-agent.guidance';

  get renderableData() {
    return this.args.part.renderableData;
  }

  get adminSummary() {
    return this.renderableData.adminSummary;
  }

  get customisationSettings() {
    return this.renderableData.finCustomisationSettings;
  }

  get toneOfVoice() {
    return this.intl.t(
      `ai-agent.settings.tone-of-voice.tones.${this.customisationSettings.aiToneOfVoice}.name`,
    );
  }

  get answerLength() {
    return this.intl.t(
      `ai-agent.settings.answer-length.values.${this.customisationSettings.aiAnswerLength}.name`,
    );
  }

  get isOnGuidancePage() {
    let currentRoute = this.router.currentRouteName;

    if (this.session.workspace.isStandaloneApp) {
      return currentRoute === this.standaloneGuidancePage;
    } else {
      return currentRoute === this.guidancePage;
    }
  }

  get guidanceRoute() {
    if (this.session.workspace.isStandaloneApp) {
      return this.standaloneGuidancePage;
    } else {
      return this.guidancePage;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::FinCustomisationSettingsApplied': typeof Inbox2ConversationStreamEventPartsFinCustomisationSettingsAppliedComponent;
    'inbox2/conversation-stream/event-parts/fin-customisation-settings-applied': typeof Inbox2ConversationStreamEventPartsFinCustomisationSettingsAppliedComponent;
  }
}
