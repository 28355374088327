/* import __COLOCATED_TEMPLATE__ from './conversation-options.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type Session from 'embercom/services/session';
import type Router from '@ember/routing/router-service';
import {
  replyChannelIsMultiParticipant,
  ReplyChannelMap,
} from 'embercom/objects/inbox/composer-pane';
import { tracked } from '@glimmer/tracking';
import type CommandKService from 'embercom/services/command-k';
import { Channel } from 'embercom/models/data/inbox/channels';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';

interface Args {
  conversation: Conversation;
  hideConversationEvents: boolean;
  onToggleConversationEvents: () => unknown;
  exportConversation: () => void;
  allowManageParticipants?: boolean;
}

interface Signature {
  Args: Args;
}

enum DropdownOption {
  newConversation = 'new-conversation',
  conversationEvents = 'conversation-events',
  exportConversation = 'export-conversation',
  manageParticipants = 'manage-participants',
  mergeInto = 'merge-into',
  doNotTranslate = 'do-not-translate',
}

type MoreOptionItem = {
  text: string;
  id: DropdownOption;
  isSelected: boolean;
  icon?: string;
  iconSet?: string;
  hotkeyId?: HotkeyID;
  tooltip?: string;
};

const defaultOptionValues = {
  [DropdownOption.newConversation]: {
    id: DropdownOption.newConversation,
    isSelected: false,
    icon: 'compose',
    iconSet: 'standard',
  },
  [DropdownOption.manageParticipants]: {
    id: DropdownOption.manageParticipants,
    isSelected: false,
    icon: 'multiple-people',
    iconSet: 'standard',
  },
  [DropdownOption.mergeInto]: {
    id: DropdownOption.mergeInto,
    isSelected: false,
    hotkeyId: HotkeyID.MergeInto,
    icon: 'merge',
  },
  [DropdownOption.exportConversation]: {
    id: DropdownOption.exportConversation,
    isSelected: false,
    icon: 'export',
  },
  [DropdownOption.conversationEvents]: {
    id: DropdownOption.conversationEvents,
    hotkeyId: HotkeyID.ToggleEvents,
    icon: 'visible',
  },
  [DropdownOption.doNotTranslate]: {
    id: DropdownOption.doNotTranslate,
    icon: 'language',
  },
};

const CROSS_USER_MERGEABLE_CHANNELS = [Channel.Email, Channel.PhoneCall];

export default class Inbox2ConversationOptionDropdownComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare router: Router;
  @service declare commandK: CommandKService;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;
  readonly replyChannelIsMultiParticipant = replyChannelIsMultiParticipant;

  @tracked isManagingParticipants = false;

  private isCrossUserMergeAllowed(conversation: Conversation): boolean {
    return (
      this.session.workspace.isFeatureEnabled('merge-conversations-cross-user') &&
      this.session.workspace.enabledCrossUserConversationMerge &&
      CROSS_USER_MERGEABLE_CHANNELS.includes(conversation?.channel?.initial)
    );
  }

  get moreOptionItems(): MoreOptionItem[] {
    if (this.session.showLightInbox) {
      return this.lightInboxDropdownOptions();
    }

    let replyChannel = ReplyChannelMap[this.args.conversation.channel.replyChannel];

    let allowManageParticipants =
      this.args.allowManageParticipants && this.replyChannelIsMultiParticipant(replyChannel);

    if (this.args.conversation.isTicket) {
      return this.ticketDropdownOptions(this.args.conversation, allowManageParticipants);
    }

    return this.conversationDropdownOptions(allowManageParticipants);
  }

  get isMergeOptionVisible() {
    let { conversation } = this.args;
    let { ticketType, participantSummaries } = conversation;

    return (
      this.session.workspace.isFeatureEnabled('channels-merge-conversations') &&
      (this.session.workspace.isFeatureEnabled('channels-merge-conversations-show-closed') ||
        !conversation.isClosed) &&
      (this.isCrossUserMergeAllowed(conversation) || participantSummaries.length <= 1) &&
      (!ticketType || ticketType.isCustomerTicketType)
    );
  }

  private disableMergeOption(
    mergeInto: MoreOptionItem,
    reason: 'closed' | 'multiple-participants' | 'not-customer',
  ): void {
    let translationKey = this.args.conversation.isTicket
      ? `inbox.conversation-header.merge-into-disabled.ticket-${reason}`
      : `inbox.conversation-header.merge-into-disabled.conversation-${reason}`;

    Object.assign(mergeInto, {
      isDisabled: true,
      tooltipText: this.intl.t(translationKey),
    });
  }

  private showDoNotTranslateOption(): boolean {
    let flagEnabled = this.session.workspace.isFeatureEnabled('realtime-translation');

    if (!flagEnabled) {
      return false;
    }

    if (!this.session.workspace.aiInboxTranslationsEnabled) {
      return false;
    }

    let languageAttribute = this.args.conversation.attributes?.find(
      (attribute) => attribute.descriptor.id === 'language',
    );
    let languageCode = languageAttribute?.value;

    if (languageCode === this.session.workspace.locale) {
      return false;
    }

    return true;
  }

  lightInboxDropdownOptions(): MoreOptionItem[] {
    let conversationEvents = {
      ...defaultOptionValues[DropdownOption.conversationEvents],
      text: this.intl.t('inbox.conversation-header.conversation-events'),
      isSelected: !this.args.hideConversationEvents,
    };
    return [conversationEvents];
  }

  conversationDropdownOptions(allowManageParticipants = false): MoreOptionItem[] {
    let manageParticipants = {
      ...defaultOptionValues[DropdownOption.manageParticipants],
      text: this.intl.t('inbox.conversation-header.manage-participants'),
    };

    let newConversation = {
      ...defaultOptionValues[DropdownOption.newConversation],
      text: this.intl.t('inbox.conversation-header.new-conversation'),
    };

    let mergeInto = {
      ...defaultOptionValues[DropdownOption.mergeInto],
      text: this.intl.t('inbox.conversation-header.merge-into'),
    };

    let exportConversation = {
      ...defaultOptionValues[DropdownOption.exportConversation],
      text: this.intl.t('inbox.conversation-header.export-conversation'),
    };

    let conversationEvents = {
      ...defaultOptionValues[DropdownOption.conversationEvents],
      text: this.intl.t('inbox.conversation-header.conversation-events'),
      isSelected: !this.args.hideConversationEvents,
    };

    let options: MoreOptionItem[] = [];

    if (allowManageParticipants) {
      options.push(manageParticipants);
    }

    if (this.session.workspace.isFeatureEnabled('channels-merge-conversations')) {
      options.push(mergeInto);

      let isClosedAndNotAllowed =
        !this.session.workspace.isFeatureEnabled('channels-merge-conversations-show-closed') &&
        this.args.conversation.isClosed;
      let hasMultipleParticipants = this.args.conversation.participantSummaries.length > 1;

      if (isClosedAndNotAllowed) {
        this.disableMergeOption(mergeInto, 'closed');
      } else if (hasMultipleParticipants && !this.isCrossUserMergeAllowed(this.args.conversation)) {
        this.disableMergeOption(mergeInto, 'multiple-participants');
      }
    }

    options.push(newConversation, exportConversation, conversationEvents);

    if (this.showDoNotTranslateOption()) {
      options.push({
        ...defaultOptionValues[DropdownOption.doNotTranslate],
        text: this.intl.t('inbox.conversation-header.do-not-translate'),
        isSelected: !this.conversationTranslationSettings.autoTranslationEnabledForConversation(
          this.args.conversation.id,
        ),
      });
    }

    return options;
  }

  ticketDropdownOptions(ticket: Conversation, allowManageParticipants = false): MoreOptionItem[] {
    let manageParticipants = {
      ...defaultOptionValues[DropdownOption.manageParticipants],
      text: this.intl.t('inbox.conversation-header.manage-participants'),
    };

    let newConversation = {
      ...defaultOptionValues[DropdownOption.newConversation],
      text: this.intl.t('inbox.conversation-header.new-conversation'),
    };

    let mergeInto = {
      ...defaultOptionValues[DropdownOption.mergeInto],
      text: this.intl.t('inbox.conversation-header.merge-into'),
    };

    let exportTicket = {
      ...defaultOptionValues[DropdownOption.exportConversation],
      text: this.intl.t('inbox.conversation-header.export-ticket'),
    };

    let ticketEvents = {
      ...defaultOptionValues[DropdownOption.conversationEvents],
      text: this.intl.t('inbox.conversation-header.ticket-events'),
      isSelected: !this.args.hideConversationEvents,
    };

    let options: MoreOptionItem[] = [];

    if (ticket.isCustomerTicket) {
      if (allowManageParticipants) {
        options.push(manageParticipants);
      }
      options.push(newConversation);
    }

    if (this.session.workspace.isFeatureEnabled('channels-merge-conversations')) {
      options.push(mergeInto);

      let isClosedAndNotAllowed =
        !this.session.workspace.isFeatureEnabled('channels-merge-conversations-show-closed') &&
        ticket.state === 'closed';
      let hasMultipleParticipants = ticket.participantSummaries.length > 1;
      let isNotCustomerTicket = !this.args.conversation?.ticketType?.isCustomerTicketType;

      if (isClosedAndNotAllowed) {
        this.disableMergeOption(mergeInto, 'closed');
      } else if (hasMultipleParticipants && !this.isCrossUserMergeAllowed(ticket)) {
        this.disableMergeOption(mergeInto, 'multiple-participants');
      } else if (isNotCustomerTicket) {
        this.disableMergeOption(mergeInto, 'not-customer');
      }
    }

    options.push(exportTicket, ticketEvents);

    if (this.showDoNotTranslateOption()) {
      options.push({
        ...defaultOptionValues[DropdownOption.doNotTranslate],
        text: this.intl.t('inbox.conversation-header.do-not-translate'),
        isSelected: !this.conversationTranslationSettings.autoTranslationEnabledForConversation(
          this.args.conversation.id,
        ),
      });
    }

    return options;
  }

  @action showManageParticipantsModal() {
    this.isManagingParticipants = true;
  }

  @action showMergeIntoModal() {
    this.commandK.registerAndShow({
      actionID: 'merge-into',
      onSelect: () => {},
    });
  }

  @action onMoreOptionsSelectionChange(item: MoreOptionItem) {
    switch (item.id) {
      case DropdownOption.newConversation:
        this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
          queryParams: {
            recipient: this.args.conversation.userSummary.id,
            ticketTypeId: undefined,
          },
        });
        break;
      case DropdownOption.conversationEvents:
        this.args.onToggleConversationEvents();
        this.intercomEventService.trackAnalyticsEvent({
          object: 'visibility',
          action: 'changed',
          section: 'conversation_header',
          conversation_id: this.args.conversation.id,
          visibility_type: 'events',
          is_visible: !this.args.hideConversationEvents,
        });
        break;
      case DropdownOption.mergeInto:
        this.showMergeIntoModal();
        this.intercomEventService.trackAnalyticsEvent({
          object: 'merge_into',
          action: 'clicked',
          section: 'conversation_header',
          conversation_id: this.args.conversation.id,
          using_modal: true,
        });
        break;
      case DropdownOption.exportConversation:
        this.args.exportConversation();
        break;
      case DropdownOption.doNotTranslate:
        this.conversationTranslationSettings.toggleAutoTranslationEnabledForConversation(
          this.args.conversation.id,
        );
        break;
      case DropdownOption.manageParticipants:
        this.showManageParticipantsModal();

        this.intercomEventService.trackAnalyticsEvent({
          object: 'manage_participants',
          action: 'clicked',
          section: 'conversation_header',
          conversation_id: this.args.conversation.id,
          using_modal: true,
        });
        break;
      default:
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationOptions': typeof Inbox2ConversationOptionDropdownComponent;
    'inbox2/conversation-options': typeof Inbox2ConversationOptionDropdownComponent;
  }
}
