/* import __COLOCATED_TEMPLATE__ from './event-group.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { helper } from '@ember/component/helper';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  partGroup: PartGroup;
  conversation: Conversation;
  isTicketStream?: boolean;
}

interface Signature {
  Args: Args;
}

// This is a list of all the renderable parts that do not use the default ticket icons, and instead
// are responsible for rendering their own
const PARTS_WITH_CUSTOM_TICKET_ICONS = [
  RenderableType.TicketShared,
  RenderableType.LinkedTicketShared,
  RenderableType.TicketStateUpdatedByAdmin,
  RenderableType.TicketStateUpdated,
  RenderableType.EntityLinked,
  RenderableType.EntitiesUnlinked,
];

const ACTION_FINISHED_PART = RenderableType.CustomActionFinished;

export default class ConversationStreamPartGroupsEventGroupComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;

  entityType = EntityType;
  renderableType = RenderableType;

  get isTicket() {
    return !!this.args.conversation.ticketType;
  }

  isNotActionFinishedPartInPreview(part: any) {
    let isPreview =
      part.renderableData.workflowPreviewConversation || part.renderableData.finPreviewConversation;
    return !(part.renderableType === ACTION_FINISHED_PART && isPreview);
  }

  partTicketIcon = helper(function ([part, conversation]: [RenderablePart, Conversation]):
    | InterfaceIconName
    | undefined {
    if (!conversation.ticketType || PARTS_WITH_CUSTOM_TICKET_ICONS.includes(part.renderableType)) {
      return undefined;
    }
    switch (conversation.ticketType?.category) {
      case TicketCategory.Request:
        return 'ticket';
      case TicketCategory.Task:
        return 'back-office';
      case TicketCategory.Tracker:
        return 'tracker';
      default:
        return undefined;
    }
  });

  get ticketType() {
    return this.args.conversation.ticketType;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::EventGroup': typeof ConversationStreamPartGroupsEventGroupComponent;
    'inbox2/conversation-stream/part-groups/event-group': typeof ConversationStreamPartGroupsEventGroupComponent;
  }
}
