/* import __COLOCATED_TEMPLATE__ from './ticket-card.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type CreateTicketCard } from '@intercom/interblocks.ts';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    ticketBlockManager: { ticketBlock: CreateTicketCard; removeTicketBlock: () => void };
  };
}

const TicketCard = templateOnlyComponent<Signature>();
export default TicketCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::TicketCard': typeof TicketCard;
  }
}
