/* import __COLOCATED_TEMPLATE__ from './merged-user-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type MergedUserComment from 'embercom/objects/inbox/renderable/merged-user-comment';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { action } from '@ember/object';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type Session from 'embercom/services/session';
interface UserRenderablePart extends RenderablePart {
  renderableData: MergedUserComment;
}

export interface MergedUserContentGroup extends PartGroup {
  parts: Array<UserRenderablePart>;
}

interface Args {
  partGroup: MergedUserContentGroup;
  conversation: Conversation;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamMergedUserContentComponent extends Component<Signature> {
  @service session!: Session;
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get user(): UserSummary {
    return this.renderableData.userSummary;
  }

  get mergedConversationId() {
    return this.renderableData.fromConversationId;
  }

  @action openMergedConversation() {
    this.inboxSidebarService.previewConversationById(this.mergedConversationId);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'merged_conversation',
      section: 'respond',
      place: 'conversation_part',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::MergedUserContent': typeof ConversationStreamMergedUserContentComponent;
    'inbox2/conversation-stream/part-groups/merged-user-content': typeof ConversationStreamMergedUserContentComponent;
  }
}
