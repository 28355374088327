/* import __COLOCATED_TEMPLATE__ from './plain-text.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';
import { type EntityType } from 'embercom/models/data/entity-types';
import { translateQuickReplyResponse } from './helpers/translations';
interface Args {
  part: RenderablePart & { renderableData: { text: string; original?: string } };
  highlights?: TableEntryHighlights;
  toTranslate: boolean;
  originalLanguage: string;
  isQuickReplyResponse: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2PlainText extends Component<Signature> {
  get highlight(): string[] | undefined {
    let highlightedPart = this.args.highlights?.parts?.filter(
      (x: { entityId: number; entityType: EntityType; matches: string[] }) =>
        x.entityId === this.args.part.entityId && x.entityType === this.args.part.entityType,
    )[0];

    if (highlightedPart) {
      return highlightedPart.matches;
    }

    return [];
  }

  get translatedText() {
    if (this.args.isQuickReplyResponse) {
      return translateQuickReplyResponse(this.args.part.renderableData, this.args.toTranslate);
    }

    return this.args.part.renderableData.text;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PlainText': typeof Inbox2PlainText;
    'inbox2/conversation-stream/plain-text': typeof Inbox2PlainText;
  }
}
