/* import __COLOCATED_TEMPLATE__ from './merged-primary-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type MergedPrimaryConversation from 'embercom/objects/inbox/renderable/merged-primary-conversation';
import { action } from '@ember/object';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type IntlService from 'embercom/services/intl';

interface MergeConversationEventPart extends RenderablePart {
  renderableData: MergedPrimaryConversation;
}

export interface MergeConversationEventGroup extends PartGroup {
  parts: Array<MergeConversationEventPart>;
}

interface Args {
  partGroup: MergeConversationEventGroup;
}

export default class MergedPrimaryConversationComponent extends Component<Args> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  get conversation() {
    return this.args.partGroup.conversation;
  }

  get part() {
    return this.args.partGroup.parts[0];
  }

  get renderableData() {
    return this.part.renderableData;
  }

  get adminSummary() {
    return this.renderableData.adminSummary;
  }

  get mergedConversationId() {
    return this.renderableData.mergedConversationId;
  }

  @action openMergedConversation() {
    if (this.mergedConversationId) {
      this.inboxSidebarService.previewConversationById(this.mergedConversationId);

      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'merged_conversation',
        section: 'respond',
        place: 'conversation_part',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::MergedPrimaryConversation': typeof MergedPrimaryConversationComponent;
    'inbox2/conversation-stream/part-groups/merged-primary-conversation': typeof MergedPrimaryConversationComponent;
  }
}
