/* import __COLOCATED_TEMPLATE__ from './operator-workflow-event.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type OperatorWorkflowEvent from 'embercom/objects/inbox/renderable/operator-workflow-event';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import LinkWithText from 'embercom/helpers/link-with-text';
import { humanReadableObjectNamesKeys } from 'embercom/models/data/matching-system/matching-constants';
import simpleCapitalize from 'embercom/lib/simple-capitalize';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';

interface OperatorWorkflowEventPart extends RenderablePart {
  renderableData: OperatorWorkflowEvent;
}

interface Args {
  part: OperatorWorkflowEventPart;
}

interface Signature {
  Args: Args;
}

interface BaseAiAgentEvent {
  type?: 'set_attributes' | 'step_reasoning' | 'default';
  i18nKey: string;
  operatorName?: string;
  title?: string;
  reason?: string;
  iconName: InterfaceIconName | null;
}
interface AiAgentDefaultEvent extends BaseAiAgentEvent {
  type: 'default';
  title?: string;
  reason?: string;
  id?: number;
  rulesetId?: number;
  actionId?: number;
}

interface AiAgentSetAttributesEvent extends BaseAiAgentEvent {
  attributeName?: string;
  attributeValue?: string;
  remainingAttributesCount?: number;
  attributes?: { name: string; value: string }[];
  type: 'set_attributes';
}

interface AiAgentTaskStepReasoningEvent extends BaseAiAgentEvent {
  step_number?: string;
  step_description?: string;
  reason?: string;
  type: 'step_reasoning';
}

type AiAgentEvent = AiAgentSetAttributesEvent | AiAgentTaskStepReasoningEvent | AiAgentDefaultEvent;
export default class Inbox2ConversationStreamEventPartsOperatorWorkflowEventComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare router: RouterService;

  @tracked openStepReasoningSection = '';

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get adminTitle() {
    let admin = this.args.part.renderableData.eventData.admin;

    if (admin) {
      return this.linkWithTextHelper.compute(['apps.app.admins.admin', admin.id, admin.name]);
    } else {
      return '';
    }
  }

  get skipAssignmentActionData() {
    let skipAssignmentAction = this.args.part.renderableData.eventData.skipAssignmentAction;

    if (skipAssignmentAction) {
      let outboundType = this.intl.t(
        humanReadableObjectNamesKeys[skipAssignmentAction.outboundType],
        {
          count: 1,
        },
      );
      let link = this.linkWithTextHelper.compute([
        `apps.app.outbound.find`,
        skipAssignmentAction.outboundId,
        skipAssignmentAction.outboundName,
      ]);
      return {
        outboundType: simpleCapitalize(outboundType),
        outboundName: link,
      };
    } else {
      return {
        outboundType: '',
        outboundName: '',
      };
    }
  }

  get aiAgentEventData() {
    return this.args.part.renderableData.eventData;
  }

  get aiAgentEvent(): AiAgentEvent | null {
    switch (this.args.part.renderableData.eventType) {
      case 'ai_agent_workflow_started':
        return {
          type: 'default',
          i18nKey: 'inbox.operator-workflow-event.events.ai_agent_workflow_started',
          operatorName: this.aiAgentEventData.admin?.name,
          id: this.aiAgentEventData?.aiAgentWorkflowStarted?.id,
          rulesetId: this.aiAgentEventData?.aiAgentWorkflowStarted?.rulesetId,
          title:
            this.aiAgentEventData?.aiAgentWorkflowStarted?.title ||
            this.intl.t('inbox.operator-workflow-event.events.workflow'),
          iconName: 'custom-attribute',
        };
      case 'ai_agent_task_started':
        return {
          type: 'default',
          i18nKey: 'inbox.operator-workflow-event.events.ai_agent_task_started',
          operatorName: this.aiAgentEventData.admin?.name,
          title:
            this.aiAgentEventData?.aiAgentTaskStarted?.title ||
            this.intl.t('inbox.operator-workflow-event.events.task'),
          iconName: this.aiAgentEventData?.aiAgentTaskStarted?.title ? 'fin' : null,
        };
      case 'ai_agent_task_finished':
        return {
          type: 'default',
          i18nKey: 'inbox.operator-workflow-event.events.ai_agent_task_finished',
          operatorName: this.aiAgentEventData.admin?.name,
          title:
            this.aiAgentEventData?.aiAgentTaskFinished?.title ||
            this.intl.t('inbox.operator-workflow-event.events.task'),
          iconName: this.aiAgentEventData?.aiAgentTaskFinished?.title ? 'fin' : null,
        };
      case 'ai_agent_task_failed':
        return {
          type: 'default',
          i18nKey: 'inbox.operator-workflow-event.events.ai_agent_task_failed',
          operatorName: this.aiAgentEventData.admin?.name,
          title:
            this.aiAgentEventData?.aiAgentTaskFailed?.title ||
            this.intl.t('inbox.operator-workflow-event.events.task'),
          reason: this.aiAgentEventData?.aiAgentTaskFailed?.reason,
          iconName: this.aiAgentEventData?.aiAgentTaskFailed?.title ? 'fin' : null,
        };
      case 'ai_agent_set_attributes': {
        let attributes = this.aiAgentEventData?.aiAgentSetAttributes;
        if (!attributes) {
          return null;
        }
        return {
          type: 'set_attributes',
          i18nKey: 'inbox.operator-workflow-event.events.ai_agent_set_attributes.set',
          operatorName: this.aiAgentEventData.admin?.name,
          attributeName: attributes[0]?.name,
          attributeValue: attributes[0]?.value,
          remainingAttributesCount: attributes.length ? attributes.length - 1 : 0,
          attributes,
          iconName: 'insert-data',
        };
      }
      case 'ai_agent_task_step_reasoning':
        return {
          i18nKey: 'inbox.operator-workflow-event.events.ai_agent_task_step_reasoning',
          operatorName: this.aiAgentEventData.admin?.name,
          step_number: this.aiAgentEventData?.aiAgentTaskStepReasoning?.step_number,
          step_description: this.aiAgentEventData?.aiAgentTaskStepReasoning?.step_description,
          reason: this.aiAgentEventData?.aiAgentTaskStepReasoning?.reason,
          iconName: 'fin',
          type: 'step_reasoning',
        };
      case 'email_verification_successful':
        return {
          i18nKey: 'inbox.operator-workflow-event.events.email_verification_successful',
          operatorName: this.aiAgentEventData.admin?.name,
          iconName: 'locked',
          type: 'default',
        };
      case 'email_verification_failed':
        return {
          i18nKey: 'inbox.operator-workflow-event.events.email_verification_failed',
          operatorName: this.aiAgentEventData.admin?.name,
          iconName: 'locked',
          type: 'default',
        };
      case 'email_already_verified':
        return {
          i18nKey: 'inbox.operator-workflow-event.events.email_already_verified',
          operatorName: this.aiAgentEventData.admin?.name,
          iconName: 'locked',
          type: 'default',
        };
      case 'email_triggered_for_verification':
        return {
          i18nKey: 'inbox.operator-workflow-event.events.email_triggered_for_verification',
          operatorName: this.aiAgentEventData.admin?.name,
          iconName: 'webhook',
          type: 'default',
          title:
            this.aiAgentEventData?.emailTriggeredForVerification?.title ||
            this.intl.t('inbox.operator-workflow-event.events.action'),
          actionId: this.aiAgentEventData?.emailTriggeredForVerification?.actionId,
        };
      default:
        return null;
    }
  }

  get translatedEvent() {
    switch (this.args.part.renderableData.eventType) {
      case 'wait_started':
        return this.waitStartedEvent;
      case 'start_workflow_requested':
        return this.intl.t('inbox.operator-workflow-event.events.start_workflow_requested', {
          admin: this.adminTitle,
          htmlSafe: true,
        });
      case 'skip_assignment_action':
        return this.intl.t('inbox.operator-workflow-event.events.skip_assignment_action', {
          ...this.skipAssignmentActionData,
          htmlSafe: true,
        });
      case 'workflow_finished':
        return this.intl.t(
          `inbox.operator-workflow-event.events.workflow_finished.${
            this.args.part.renderableData.eventData.workflowFinished!.reason
          }`,
          {
            htmlSafe: true,
          },
        );
      case 'tag_user':
        return this.intl.t('inbox.operator-workflow-event.events.tag_user', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'remove_tag_from_user':
        return this.intl.t('inbox.operator-workflow-event.events.remove_tag_from_user', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'add_tag_to_conversation':
        return this.intl.t('inbox.operator-workflow-event.events.add_tag_to_conversation', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'remove_tag_from_conversation':
        return this.intl.t('inbox.operator-workflow-event.events.remove_tag_from_conversation', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'user_inactivity_conversation_autoclosed':
        return this.intl.t(
          'inbox.operator-workflow-event.events.user_inactivity_conversation_autoclosed',
          {
            minutes:
              this.args.part.renderableData.eventData.userInactivityConversationAutoClosed!
                .endUserInactivityTimer / 60,
            htmlSafe: true,
          },
        );
      case 'language_override_updated':
        if (!this.args.part.renderableData.eventData.languageOverrideUpdated!.previousValue) {
          return this.intl.t('inbox.operator-workflow-event.events.language_override_updated.set', {
            language: this.intl.languageNameFromCode(
              this.args.part.renderableData.eventData.languageOverrideUpdated!.newValue,
            ),
            htmlSafe: true,
          });
        }
        return this.intl.t(
          'inbox.operator-workflow-event.events.language_override_updated.changed',
          {
            previousValue: this.intl.languageNameFromCode(
              this.args.part.renderableData.eventData.languageOverrideUpdated!.previousValue,
            ),
            newValue: this.intl.languageNameFromCode(
              this.args.part.renderableData.eventData.languageOverrideUpdated!.newValue,
            ),
            htmlSafe: true,
          },
        );
      case 'user_data_attribute_updated':
        return this.userDataAttributeUpdatedEvent;
      case 'ai_agent_ignoring_user_message':
        return this.intl.t(
          `inbox.operator-workflow-event.events.ai_agent_ignoring_user_message.${
            this.args.part.renderableData.eventData.aiAgentIgnoringUserMessage!.reason
          }`,
          {
            htmlSafe: true,
          },
        );
      default:
        return this.intl.t(
          `inbox.operator-workflow-event.events.${this.args.part.renderableData.eventType}`,
          {
            default: 'inbox.operator-workflow-event.events.unknown',
            ...this.args.part.renderableData.eventData,
          },
        );
    }
  }

  get waitStartedEvent() {
    let durationType =
      this.args.part.renderableData.eventData.waitStarted?.durationType || 'custom';

    if (durationType === 'custom') {
      let customDuration = this.args.part.renderableData.eventData.waitStarted?.customDuration || 0;
      let days = Math.floor(customDuration / 86400);
      let hours = Math.floor((customDuration % 86400) / 3600);
      let minutes = Math.floor((customDuration % 3600) / 60);
      let seconds = Math.floor(customDuration % 60);

      let formatKey = '';
      if (days > 0) {
        formatKey += 'd';
      }
      if (hours > 0) {
        formatKey += 'h';
      }
      if (minutes > 0) {
        formatKey += 'm';
      }
      if (seconds > 0) {
        formatKey += 's';
      }

      return this.intl.t(`inbox.operator-workflow-event.events.wait_started.${formatKey}`, {
        days,
        hours,
        minutes,
        seconds,
      });
    } else {
      return this.intl.t(`inbox.operator-workflow-event.events.wait_started.${durationType}`);
    }
  }

  get tagName() {
    let actionData = this.args.part.renderableData.eventData.actionData;

    if (!actionData) {
      return '';
    }

    if (actionData.tagId) {
      return this.session.store.peekRecord('tag', actionData.tagId)?.name || '';
    }

    return '';
  }

  @action
  toggleStepReasoningSection() {
    this.openStepReasoningSection =
      this.openStepReasoningSection === 'open_reasoning_step' ? '' : 'open_reasoning_step';
  }

  get userDataAttributeUpdatedEvent() {
    let attribute = this.args.part.renderableData.eventData.actionData!.attributeIdentifier;
    let value = this.args.part.renderableData.eventData.actionData!.value?.toString();
    if (!attribute || !value) {
      return '';
    }

    if (attribute.startsWith('custom_data.')) {
      attribute = attribute.replace('custom_data.', '');
    }

    return this.intl.t('inbox.operator-workflow-event.events.user_data_attribute_updated', {
      attribute,
      value,
      htmlSafe: true,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::OperatorWorkflowEvent': typeof Inbox2ConversationStreamEventPartsOperatorWorkflowEventComponent;
    'inbox2/conversation-stream/event-parts/operator-workflow-event': typeof Inbox2ConversationStreamEventPartsOperatorWorkflowEventComponent;
  }
}
