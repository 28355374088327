/* import __COLOCATED_TEMPLATE__ from './custom-answer-applied.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type CustomAnswerApplied from 'embercom/objects/inbox/renderable/custom-answer-applied';
import type IntlService from 'embercom/services/intl';

interface CustomAnswerAppliedPart extends RenderablePart {
  renderableData: CustomAnswerApplied;
}

interface Args {
  part: CustomAnswerAppliedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsCustomAnswerAppliedComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  customAnswersIndexPage = 'apps.app.automation.fin-ai-agent.custom-answers';
  customAnswersShowPage = 'apps.app.automation.fin-ai-agent.custom-answers.answer.show.index';

  get renderableData() {
    return this.args.part.renderableData;
  }

  get adminSummary() {
    return this.renderableData.adminSummary;
  }

  get customAnswer() {
    return this.renderableData.customAnswer;
  }

  get customAnswerName() {
    return this.customAnswer.title;
  }

  get customAnswerId() {
    return this.customAnswer.id;
  }

  get isDeletedCustomAnswer() {
    return this.customAnswer.id === -1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::CustomAnswerApplied': typeof Inbox2ConversationStreamEventPartsCustomAnswerAppliedComponent;
    'inbox2/conversation-stream/event-parts/custom-answer-applied': typeof Inbox2ConversationStreamEventPartsCustomAnswerAppliedComponent;
  }
}
