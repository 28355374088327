/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'alert'
  | 'danger'
  | 'success'
  | 'floating';

type ButtonSize = 'base' | 'sm' | 'narrow' | 'xs' | 'lg' | 'fill' | 'call';

export interface Inbox2ButtonArgs {
  type: ButtonType;
  size?: ButtonSize;
  forceActive?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  grayscale?: boolean;
}

interface Signature {
  Element: HTMLButtonElement;
  Args: Inbox2ButtonArgs;
  Blocks: {
    default: [];
  };
}

export default class Inbox2CommonButtonComponent extends Component<Signature> {
  get size() {
    return this.args.size ?? 'base';
  }

  get type() {
    return this.args.type ?? 'primary';
  }

  get sizeClasses() {
    if (this.args.type === 'link' && !this.args.iconOnly) {
      return 'px-1';
    }

    let identifier = {
      xs: 'inbox2__button--xs',
      sm: 'inbox2__button--small',
      base: '',
      lg: 'inbox2__button--large',
      narrow: 'inbox2__button--small',
      fill: 'inbox2__button--fill',
      call: 'inbox2__button--call',
    }[this.size];

    let fontSize = {
      xs: 'text-sm',
      sm: 'text-sm',
      base: 'text-base',
      lg: 'text-base',
      narrow: 'text-base',
      fill: 'text-base',
      call: 'text-base',
    }[this.size];

    let padding = {
      xs: 'p-0',
      sm: this.args.iconOnly ? 'p-1' : 'py-1 px-2',
      base: this.args.iconOnly ? 'p-2' : 'py-2 px-3',
      lg: this.args.iconOnly ? 'p-4' : 'px-6 py-3',
      narrow: 'py-1 px-0',
      fill: 'px-3 py-2',
      call: this.args.iconOnly ? '' : 'px-4',
    }[this.size];

    return `inbox2__button ${identifier} ${fontSize} ${padding}`;
  }

  get colorClasses(): string | void {
    return {
      primary: this.primaryClasses,
      secondary: this.secondaryClasses,
      tertiary: this.tertiaryClasses,
      alert: this.alertClasses,
      link: this.linkClasses,
      danger: this.dangerClasses,
      success: this.successClasses,
      floating: this.onFloatingClasses,
    }[this.type].trim();
  }

  private get primaryClasses(): string {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
    text-on-neutral ring-neutral-border-emphasis
      ${this.args.forceActive ? 'bg-neutral-fill-emphasis' : 'bg-neutral-fill'}
      hover:bg-neutral-fill-emphasis
      active:bg-neutral-fill-emphasis
      focus-visible:bg-neutral-fill-emphasis
    `;
  }

  private get secondaryClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      text-default ring-neutral-border-emphasis
      ${this.args.forceActive ? 'bg-neutral-container-emphasis' : 'bg-neutral-container'}
      hover:bg-neutral-container-emphasis
      active:bg-neutral-container-emphasis
      focus-visible:bg-neutral-container-emphasis
    `;
  }

  private get tertiaryClasses() {
    if (this.args.disabled) {
      return `text-disabled pointer-events-none`;
    }

    return `
      text-default ring-neutral-border
      ${this.args.forceActive ? 'bg-neutral-container-emphasis' : 'bg-transparent'}
      hover:bg-neutral-container-emphasis
      active:bg-neutral-container-emphasis
      focus-visible:bg-neutral-container-emphasis
    `;
  }

  private get dangerClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      text-on-error ring-error-border
      ${this.args.forceActive ? 'bg-error-fill-emphasis' : 'bg-error-fill'}
      hover:bg-error-fill-emphasis
      active:bg-error-fill-emphasis
      focus-visible:bg-error-fill-emphasis
    `;
  }

  private get alertClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
    text-default ring-error-border
      ${this.args.forceActive ? 'bg-error-container' : 'bg-error-container'}
      active:error-container-emphasis
      focus-visible:error-container-emphasis
    `;
  }

  private get successClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      text-on-success ring-success-border
      ${this.args.forceActive ? 'bg-success-fill-emphasis' : 'bg-success-fill'}
      hover:bg-success-fill-emphasis
      active:bg-success-fill-emphasis
      focus-visible:bg-success-fill-emphasis
    `;
  }

  private get onFloatingClasses(): string {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-base-input text-default border-accent-border-emphasis guide-library-el-active'
          : 'bg-base-input text-default'
      }
      hover:bg-base-input hover:border-neutral-border-emphasis
      active:bg-base-input active:border-neutral-border-emphasis active:ring-1
      focus-visible:bg-base-input focus-visible:border-neutral-border-emphasis focus-visible:ring-2 focus-visible:ring-neutral-border-emphasis
    `;
  }

  private get linkClasses() {
    if (this.args.disabled) {
      return `text-disabled pointer-events-none`;
    }
    let colour = this.args.grayscale ? 'text-muted' : 'text-default';
    return `
      ${this.args.forceActive ? 'text-accent' : `bg-transparent ${colour}`}
      hover:text-accent
      active:text-accent
      focus-visible:text-accent focus-visible:ring-2 focus-visible:ring-neutral-border
    `;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Button': typeof Inbox2CommonButtonComponent;
  }
}
