/* import __COLOCATED_TEMPLATE__ from './ticket-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { DescriptorUtils, type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import type Snackbar from 'embercom/services/snackbar';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { useResource } from 'ember-resources';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import ConditionalAttributesEvaluator from 'embercom/services/conditional-attributes-evaluator';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import type { Brand } from 'embercom/components/new-settings/workspace/brands/types/brand';
import type Inbox2BrandService from 'embercom/services/inbox2-brand-service';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';

interface Args {
  conversation: Conversation;
  displayTicketId: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketAttributes extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare inbox2BrandService: Inbox2BrandService;
  @trackedReset('args.conversation.id') ticketTypeId: number | undefined;
  @tracked ticketAttributeDescriptors: TicketDescriptor[] | undefined;
  @tracked brand;

  @tracked brands: Brand[] | undefined = [];
  @tracked selectedBrandId: number | string | undefined;
  @tracked selectedBrandName;
  @tracked brandsList: any;
  @tracked DEFAULT_BRAND_ID;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (
      this.inbox2BrandService.isAppOnRightMultibrandPlan ||
      this.session.workspace.isFeatureEnabled('multibrand-tickets')
    ) {
      this.initialiseBrands();
      this.DEFAULT_BRAND_ID = this.selectedBrandId = this.session.workspace.id;
      this.selectedBrandName = this.session.workspace.name;
      // @ts-ignore
      this.ticketAttributeDescriptors = this.session.workspace.conversationAttributeDescriptors;
      this.brand = this.ticketAttributeDescriptors?.filter(
        // @ts-ignore
        (descriptor) => descriptor.id === 'brand',
      )[0];
    }
  }

  @action
  async initialiseBrands() {
    try {
      await this.inbox2BrandService.brands.promise;
      this.brands = this.inbox2BrandService.brands.value as Brand[];
      if (this.brands) {
        this.buildBrandsList();
      }
    } catch (error) {
      console.error('Failed to load brands:', error);
    }
  }

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.conversation.ticketType?.id,
    conversation: this.args.conversation,
  }));

  get emojiUrl(): string {
    return intermoji.twemojiSVGUri(this.args.conversation?.ticketType?.emoji ?? '');
  }

  get conditionalAttributesEvaluator() {
    let ticketTypes = this.session.workspace.ticketTypes.value;

    let ticketType = ticketTypes?.filter(
      (ticketType: any) => ticketType.id === this.args.conversation.ticketType?.id,
    )[0];

    if (ticketType?.descriptors && ticketType.descriptors?.length > 0) {
      return new ConditionalAttributesEvaluator({
        conversation: this.args.conversation,
        descriptors: ticketType.descriptors,
        owner: getOwner(this),
      });
    }

    return undefined;
  }

  get brandAttribute() {
    return this.conversationAttributesById.brand;
  }

  get brandDescriptor() {
    return this.conversationAttributesById.brand.descriptor;
  }

  buildBrandsList() {
    let defaultBrandOption = {
      text: this.session.workspace.name,
      value: this.DEFAULT_BRAND_ID,
    };

    this.brandsList = [
      defaultBrandOption,
      ...(this.customBrands || [])
        .map((brand: Brand) => ({
          text: brand.name,
          value: Number(brand.id),
        }))
        .filter((brand) => typeof brand.value === 'number' && !isNaN(brand.value))
        .sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        }),
    ];
  }

  get customBrands() {
    if (!this.brands) {
      return [];
    }
    return this.brands?.filter((brand) => !brand.isDefault) ?? [];
  }

  @action
  setSelectedBrandName(brandId: number | null | undefined) {
    // @ts-ignore
    if (brandId === this.DEFAULT_BRAND_ID) {
      this.selectedBrandName = this.session.workspace.name;
    } else {
      let foundBrand = this.brands?.find((brand) => brand.id === brandId);
      this.selectedBrandName = foundBrand?.name;
    }
  }

  get ticketAttributes() {
    let ticketTypeId = this.args.conversation.ticketType?.id;
    let ticketTypes = this.session.workspace.ticketTypes.value;

    return DescriptorUtils.getVisibleAttributes(
      ticketTypeId,
      ticketTypes,
      this.ticketTypeResource,
      this.conditionalAttributesEvaluator,
      this.ticketAttributesById,
      this.args.conversation.id,
    );
  }

  get ticketIdValue() {
    return `${this.args.conversation.ticketId}` || `—`;
  }

  get linkedTicketIdValue() {
    return this.args.conversation.ticketId ? `#${this.ticketIdValue}` : this.ticketIdValue;
  }

  get ticketAttributesById() {
    return (
      this.args.conversation?.ticketAttributes?.reduce(
        (byIds, attribute) => {
          byIds[attribute.descriptor.id] = attribute;
          return byIds;
        },
        {} as Record<string, TicketAttributeSummary>,
      ) ?? {}
    );
  }

  get conversationAttributesById() {
    return (
      this.args.conversation?.attributes?.reduce(
        (byIds, attribute) => {
          byIds[attribute.descriptor.id] = attribute;
          return byIds;
        },
        {} as Record<string, ConversationAttributeSummary>,
      ) ?? {}
    );
  }

  @action async onUpdateTicketAttribute(
    attribute: TicketAttributeSummary,
    descriptor?: TicketDescriptor,
  ) {
    if (descriptor?.requiredToCreate && attribute.isEmpty) {
      this.snackbar.notify(this.intl.t('inbox.ticket-attributes.attribute-required'), {
        clearable: true,
      });
      attribute.rollback();
      return;
    }

    if (this.ticketTypeId || !attribute.isUpdated) {
      return;
    }

    await this.conditionalAttributesEvaluator?.updateAttribute(attribute, this.args.conversation);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketAttributes': typeof TicketAttributes;
    'inbox2/conversation-page/ticket-panel/ticket-attributes': typeof TicketAttributes;
  }
}
