/* import __COLOCATED_TEMPLATE__ from './user-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type QuickReplyResponse from 'embercom/objects/inbox/renderable/quick-reply-response';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type CustomBotSummary from 'embercom/objects/inbox/custom-bot-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { getLanguageFromPartGroup, translatedPartExists } from '../helpers/translations';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import type IntlService from 'ember-intl/services/intl';
interface UserRenderablePart extends RenderablePart {
  renderableData: UserComment | QuickReplyResponse;
}

interface UserPartGroup extends PartGroup {
  parts: Array<UserRenderablePart>;
}

interface Args {
  partGroup: UserPartGroup;
  conversation: Conversation;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamUserContentComponent extends Component<Signature> {
  @service session!: Session;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;
  @service declare intl: IntlService;
  @tracked showOriginalMessageToggle = false;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get user(): UserSummary {
    return this.renderableData.userSummary;
  }

  get customBot(): CustomBotSummary | undefined {
    if (this.renderableData.renderableType === RenderableType.UserComment) {
      return this.renderableData.customBotSummary;
    }
    return undefined;
  }

  get isQuickReplyResponse() {
    return this.renderableData.renderableType === RenderableType.QuickReplyResponse;
  }

  get autoTranslationEnabled() {
    return this.conversationTranslationSettings.autoTranslationEnabledForConversation(
      this.args.conversation?.id,
    );
  }

  get showOriginalMessage(): boolean {
    if (!this.autoTranslationEnabled) {
      return true;
    }
    return this.showOriginalMessageToggle;
  }

  get showTranslatedMessage() {
    return !this.showOriginalMessage;
  }

  get translatedPartExists() {
    return translatedPartExists(this.args.partGroup);
  }

  get originalLanguage() {
    return getLanguageFromPartGroup(this.args.partGroup, this.intl.primaryLocale, true) || '';
  }

  @action toggleTranslation() {
    this.showOriginalMessageToggle = !this.showOriginalMessageToggle;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::UserContent': typeof ConversationStreamUserContentComponent;
    'inbox2/conversation-stream/part-groups/user-content': typeof ConversationStreamUserContentComponent;
  }
}
