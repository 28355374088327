/* import __COLOCATED_TEMPLATE__ from './bot-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type CustomBot from 'embercom/objects/inbox/renderable/custom-bot';
import type BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import type ChannelAndReplyTimeExpectation from 'embercom/objects/inbox/renderable/channel-and-reply-time-expectation';
import type BotNote from 'embercom/objects/inbox/renderable/bot-note';
import type FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { getLanguageFromPartGroup, translatedPartExists } from '../helpers/translations';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import type Conversation from 'embercom/objects/inbox/conversation';

interface BotContentRenderablePart extends RenderablePart {
  renderableData: CustomBot | BotComment | ChannelAndReplyTimeExpectation | BotNote | FinAnswer;
}

interface BotContentPartGroup extends PartGroup {
  parts: Array<BotContentRenderablePart>;
}

interface Args {
  isTicketStream: boolean;
  partGroup: BotContentPartGroup;
  isReadOnly: boolean;
  conversation?: Conversation;
  isStandalone?: boolean;
}

interface Signature {
  Args: Args;
}
export default class ConversationStreamTeammateContentComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;

  @tracked showOriginalMessageToggle = true;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get avatarObject() {
    if (this.customBotSummary) {
      return {
        id: this.customBotSummary.id,
        name: this.intl.t('inbox.custom-bot', { name: this.customBotSummary.title }),
        imageURL: this.renderableData.entity.imageURL,
      };
    } else {
      return this.renderableData.creatingEntity.object;
    }
  }

  get avatarRoute() {
    if (this.customBotSummary) {
      return 'apps.app.operator.custom-bots.custom-bot.show';
    } else {
      return 'apps.app.admins.admin';
    }
  }

  get customBotSummary() {
    return 'customBotSummary' in this.renderableData && this.renderableData.customBotSummary;
  }

  get currentTranslatablePart() {
    return this.args.partGroup.parts.find((p) => p.renderableType === 7 || p.renderableType === 58);
  }

  get autoTranslationEnabled() {
    return this.conversationTranslationSettings.autoTranslationEnabledForConversation(
      this.args.conversation?.id,
    );
  }

  get showOriginalMessage(): boolean {
    if (!this.autoTranslationEnabled) {
      return false;
    }
    return this.showOriginalMessageToggle;
  }

  get showTranslatedMessage() {
    return !this.showOriginalMessage;
  }

  get translatedPartExists() {
    return translatedPartExists(this.args.partGroup);
  }

  get translatedLanguage() {
    return getLanguageFromPartGroup(this.args.partGroup, this.intl.primaryLocale) || '';
  }

  @action
  toggleTranslation() {
    this.showOriginalMessageToggle = !this.showOriginalMessageToggle;
  }

  appendPublicSources = (part: BotContentRenderablePart) => {
    return (
      'sources' in part.renderableData &&
      part.renderableData.sources &&
      part.renderableData.sources.length > 0
    );
  };

  partHasTranslations(part: BotContentRenderablePart) {
    let singlePartGroup = { parts: [part] } as unknown as PartGroup;
    return translatedPartExists(singlePartGroup);
  }

  isBotCommentOrFinAnswer(part: RenderablePart): boolean {
    return (
      part.renderableType === RenderableType.BotComment ||
      part.renderableType === RenderableType.FinAnswer
    );
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::BotContent': typeof ConversationStreamTeammateContentComponent;
    'inbox2/conversation-stream/part-groups/bot-content': typeof ConversationStreamTeammateContentComponent;
  }
}
