/* import __COLOCATED_TEMPLATE__ from './merged-secondary-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type MergedSecondaryConversation from 'embercom/objects/inbox/renderable/merged-secondary-conversation';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';

interface MergeSecondaryConversationEventPart extends RenderablePart {
  renderableData: MergedSecondaryConversation;
}

export interface MergeSecondaryConversationEventGroup extends PartGroup {
  parts: Array<MergeSecondaryConversationEventPart>;
}

interface Args {
  partGroup: MergeSecondaryConversationEventGroup;
}

export interface MergedSecondaryConversationPart extends RenderablePart {
  renderableData: MergedSecondaryConversation;
}

export default class MergedSecondaryConversationComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get conversation() {
    return this.args.partGroup.conversation;
  }

  get part() {
    return this.args.partGroup.parts[0];
  }

  get text() {
    let translationString;
    if (this.conversation.isTicket) {
      translationString = 'inbox.merge-into.merge.into-ticket';
    } else {
      translationString = 'inbox.merge-into.merge.into-conversation';
    }

    return this.intl.t(translationString, {
      name: this.part.renderableData.adminSummary.name,
      id: this.part.renderableData.mergeIntoConversationId,
      url: this.router.urlFor(
        'inbox.workspace.inbox.conversation.conversation',
        this.part.renderableData.mergeIntoConversationId,
      ),
      htmlSafe: true,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::MergedSecondaryConversation': typeof MergedSecondaryConversationComponent;
    'inbox2/conversation-stream/part-groups/merged-secondary-conversation': typeof MergedSecondaryConversationComponent;
  }
}
